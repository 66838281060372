import { Collapse, IconButton } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AirportShuttle from "@material-ui/icons/AirportShuttle";
import Archive from "@material-ui/icons/Archive";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Build from "@material-ui/icons/Build";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import DoneIcon from "@material-ui/icons/Done";
import DonutSmall from "@material-ui/icons/DonutSmall";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FaceIcon from "@material-ui/icons/Face";
import GroupIcon from "@material-ui/icons/Group";
import LanguageIcon from "@material-ui/icons/Language";
import ListAltIcon from "@material-ui/icons/ListAlt";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from "@material-ui/icons/Settings";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import TimelineIcon from "@material-ui/icons/Timeline";
import React, { Children, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeUserSession } from "../../Utils/Common";
import { useStyles } from "../../Utils/Styles";
import { showSidebar } from "../../redux/actions/sidebarActions";
function CollapseGroup({
  mainItemAction,
  mainItemIcon,
  header,
  defaultOpen,
  children,
  collapseStyle,
}) {
  const [open, setOpen] = useState(defaultOpen || false);
  const _collapseStyle = collapseStyle || { background: "#e6e6e6" };
  return (
    <>
      <ListItem
        button={!children}
        onClick={!children && mainItemAction ? mainItemAction : null}
        style={open ? { boxShadow: "1px 1px 2px" } : null}
      >
        {mainItemIcon ? (
          <ListItemIcon
            onClick={mainItemAction ? mainItemAction : null}
            style={{
              cursor: mainItemAction ? "pointer" : "",
            }}
          >
            {mainItemIcon}
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={header}
          onClick={mainItemAction ? mainItemAction : null}
          style={{
            cursor: mainItemAction ? "pointer" : "",
          }}
          primaryTypographyProps={{
            style: { fontSize: header.length > 15 ? 13 : "" },
          }}
        />
        {children ? (
          <IconButton
            onClick={(e) => {
              setOpen(!open);
            }}
            style={{ position: "absolute", right: 0, zIndex: 9999 }}
          >
            {open ? <ExpandLess></ExpandLess> : <ExpandMore></ExpandMore>}
          </IconButton>
        ) : null}
      </ListItem>
      <Collapse in={open} style={_collapseStyle}>
        {children && Children.map(children, (Child) => <>{Child}</>)}
      </Collapse>
    </>
  );
}

/**
 * ContactsModule
 * DeviceModule
 * MaintenanceModule
 * SoftwareModule
 * SystemModule
 */

export default function AdminSidebarList() {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div>
      <ListItem
        button
        onClick={() => {
          history.push("/cpanel");
          dispatch(showSidebar(false));
        }}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={t("adminSideBar.controlPanel")} />
      </ListItem>

      <CollapseGroup
        mainItemAction={() => {
          history.push("/cpanel/contacts");
          dispatch(showSidebar(false));
        }}
        mainItemIcon={<BusinessIcon />}
        header={t("mainSideBar.contacts")}
      ></CollapseGroup>

      <CollapseGroup
        mainItemAction={() => {
          history.push("/cpanel/devices/dashboard");
          dispatch(showSidebar(false));
        }}
        mainItemIcon={<DevicesOtherIcon />}
        header={t("adminSideBar.devices")}
      >
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/devices");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary={t("search")} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/worldmap");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={t("mainSideBar.worldmap")} />
        </ListItem>
      </CollapseGroup>

      <CollapseGroup
        mainItemIcon={<DonutSmall />}
        header={t("lifeCycleManagement.header")}
      >
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/production");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary={t("mainSideBar.production")} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/transfers");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <AirportShuttle />
          </ListItemIcon>
          <ListItemText primary={t("mainSideBar.transfers")} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/demos");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <EmojiTransportationIcon />
          </ListItemIcon>
          <ListItemText primary={t("mainSideBar.demos")} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/dispatches");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <DoneIcon />
          </ListItemIcon>
          <ListItemText primary={t("mainSideBar.dispatches")} />
        </ListItem>
      </CollapseGroup>

      <CollapseGroup
        mainItemAction={() => {
          history.push("/cpanel/maintenance");
          dispatch(showSidebar(false));
        }}
        mainItemIcon={<Build />}
        header={t("maintenance.header")}
      >
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/maintenance/tickets");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={t("deviceDetails.maintenanceTickets")} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/maintenance/statistics");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <TimelineIcon />
          </ListItemIcon>
          <ListItemText primary={t("maintenance.statistics")} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/alerts");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary={t("adminSideBar.alerts")} />
        </ListItem>
      </CollapseGroup>

      <CollapseGroup
        mainItemAction={() => {
          history.push("/cpanel/software");
          dispatch(showSidebar(false));
        }}
        mainItemIcon={<Archive />}
        header={t("software.header")}
      >
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/crashlogs");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <SmsFailedIcon />
          </ListItemIcon>
          <ListItemText primary={t("deviceDetails.crashLogs")} />
        </ListItem>
      </CollapseGroup>

      <CollapseGroup
        mainItemAction={() => {
          history.push("/cpanel/system");
          dispatch(showSidebar(false));
        }}
        mainItemIcon={<SettingsIcon />}
        header={t("system.header")}
      >
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/users");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={t("adminSideBar.users")} />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/cpanel/settings");
            dispatch(showSidebar(false));
          }}
        >
          <ListItemIcon>
            <FaceIcon />
          </ListItemIcon>
          <ListItemText primary={t("mainSideBar.settings")} />
        </ListItem>
      </CollapseGroup>

      <ListItem
        button
        className={classes.sideBarButton}
        onClick={() => {
          history.push("/login");
          removeUserSession();
          dispatch(showSidebar(false));
        }}
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary={t("topbar.logout")} />
      </ListItem>
    </div>
  );
}
