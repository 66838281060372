import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Clear from "@material-ui/icons/Clear";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DeviceSearchAutocomplete from "../../../Components/DeviceSearchAutocomplete";
import OrderSearchAutocomplete from "../../../Components/OrderSearchAutocomplete";
import StockItemSearch from "../../../Components/StockItems/StockItemSearch";
import Title from "../../../Components/Title";
import UserSearchAutocomplete from "../../../Components/UserSearchAutocomplete";
import { getToken, requestErrorHandler } from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;

export default function NewEmailList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [emaillist, setEmailList] = useState();
  const dispatch = useDispatch();
  const [resetKey, setResetKey] = useState("dsfafasd");

  const submitList = () => {
    if (!emaillist) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        list: emaillist,
        add_list: true,
      }),
    };
    fetch(server + "/edit_email_list", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.replace("/cpanel/emaillists");
        }
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link color="inherit" href="/cpanel/emaillists">
                  <Typography>{t("emailLists.header")}</Typography>
                </Link>
                <Typography>{t("emailLists.editEmailList")}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Title>{t("emailLists.editEmailList")}</Title>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label={t("emailLists.name")}
                value={emaillist?.name}
                onChange={(e) => {
                  setEmailList({ ...emaillist, name: e.target.value });
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(e, v) => {
                        setEmailList({
                          ...emaillist,
                          allOrderAlerts: v ? 1 : 0,
                        });
                      }}
                    />
                  }
                  label={t("emailLists.subscribeAllOrderAlerts")}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(e, v) => {
                        setEmailList({
                          ...emaillist,
                          allStockAlerts: v ? 1 : 0,
                        });
                      }}
                    />
                  }
                  label={t("emailLists.subscribeAllStockAlerts")}
                />
              </Grid>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Title>{t("emailLists.users")}</Title>
              {emaillist &&
                emaillist.EmailListUsers &&
                emaillist.EmailListUsers.map((u, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: "rgb(177 255 184)",
                    }}
                  >
                    {u.User.emailAddress}
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setEmailList({
                          ...emaillist,
                          EmailListUsers: emaillist.EmailListUsers.filter(
                            (f) => f.User.id !== u.User.id
                          ),
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </MenuItem>
                ))}
              <UserSearchAutocomplete
                style={{ marginTop: 5 }}
                email={true}
                key={resetKey}
                label={t("emailLists.searchUser")}
                onChange={(e, v) => {
                  if (!v || !v.emailAddress) {
                    return;
                  }
                  setEmailList({
                    ...emaillist,
                    EmailListUsers: emaillist?.EmailListUsers
                      ? [...emaillist.EmailListUsers, { User: v }]
                      : [{ User: v }],
                  });
                  setResetKey(new Date().toISOString());
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Title>{t("emailLists.devices")}</Title>
              {emaillist &&
                emaillist.EmailListDevices &&
                emaillist?.EmailListDevices.map((u, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: "rgb(177 255 184)",
                    }}
                  >
                    {u.Device.serialNumber}
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setEmailList({
                          ...emaillist,
                          EmailListDevices: emaillist.EmailListDevices.filter(
                            (f) => f.Device.id !== u.Device.id
                          ),
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </MenuItem>
                ))}
              <DeviceSearchAutocomplete
                style={{ marginTop: 5 }}
                key={resetKey}
                label={t("emailLists.searchDevice")}
                onChange={(e, v) => {
                  if (!v || !v.serialNumber) {
                    return;
                  }
                  setEmailList({
                    ...emaillist,
                    EmailListDevices: emaillist?.EmailListDevices
                      ? [...emaillist.EmailListDevices, { Device: v }]
                      : [{ Device: v }],
                  });
                  setResetKey(new Date().toISOString());
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Title>{t("emailLists.orders")}</Title>
              {emaillist &&
                emaillist.EmailListOrders &&
                emaillist.EmailListOrders.map((u, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: "rgb(177 255 184)",
                      fontSize: 15,
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {u.Order.number}
                        </Typography>
                      </Grid>
                      <Grid item container direction="row">
                        <Typography style={{ fontSize: 12 }}>
                          {u.Order.clientInfo.companyName}
                        </Typography>
                        <Typography
                          style={{ fontSize: 12, whiteSpace: "pre-wrap" }}
                        >
                          {" "}
                          {u.Order.clientInfo.location}
                        </Typography>
                        <Typography
                          style={{ fontSize: 12, whiteSpace: "pre-wrap" }}
                        >
                          {" "}
                          {u.Order.totalPrice} {u.Order.currency}
                        </Typography>
                      </Grid>
                    </Grid>
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setEmailList({
                          ...emaillist,
                          EmailListOrders: emaillist.EmailListOrders.filter(
                            (f) => f.Order.id !== u.Order.id
                          ),
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </MenuItem>
                ))}
              <OrderSearchAutocomplete
                style={{ marginTop: 5 }}
                key={resetKey + "1"}
                additionalDataFields={[
                  "clientInfo.companyName",
                  "clientInfo.location",
                  "totalPrice",
                  "currency",
                ]}
                label={t("emailLists.searchOrder")}
                onChange={(e, v) => {
                  if (!v || !v.number) {
                    return;
                  }
                  setEmailList({
                    ...emaillist,
                    EmailListOrders: emaillist?.EmailListOrders
                      ? [...emaillist.EmailListOrders, { Order: v }]
                      : [{ Order: v }],
                  });
                  setResetKey(new Date().toISOString());
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Title>{t("emailLists.stock")}</Title>
              {emaillist &&
                emaillist.EmailListStockItems &&
                emaillist.EmailListStockItems.map((u, i) => (
                  <MenuItem
                    key={i}
                    style={{
                      backgroundColor: "rgb(177 255 184)",
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography style={{ fontSize: 15 }}>
                          {u.StockItem.sku}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: 12 }}>
                          {u.StockItem.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <IconButton
                      style={{ position: "absolute", right: 0 }}
                      onClick={() => {
                        setEmailList({
                          ...emaillist,
                          EmailListStockItems:
                            emaillist.EmailListStockItems.filter(
                              (f) => f.StockItem.id !== u.StockItem.id
                            ),
                        });
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </MenuItem>
                ))}
              <StockItemSearch
                allowNewItems={false}
                clearOnSelect={true}
                includeEmailLists={true}
                label={t("emailLists.searchStockItem")}
                onChange={(e, v) => {
                  if (!v || !v.name) {
                    return;
                  }
                  setEmailList({
                    ...emaillist,
                    EmailListStockItems: emaillist?.EmailListStockItems
                      ? [...emaillist.EmailListStockItems, { StockItem: v }]
                      : [{ StockItem: v }],
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!emaillist}
                variant="contained"
                color="primary"
                onClick={() => {
                  submitList();
                }}
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
