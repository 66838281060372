import {
  Button,
  CircularProgress,
  Link,
  Typography,
  withWidth,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import BrowseContactsDialog from "../../Components/Contacts/BrowseContactsDialog";
import ContactInfoBadge from "../../Components/Contacts/ContactInfoBadge";
import DeviceDialog from "../../Components/DeviceDialog";
import DeviceSearchAutocomplete from "../../Components/DeviceSearchAutocomplete";
import DeviceStatusBadge from "../../Components/DeviceStatusBadge";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import UserSearchAutocomplete from "../../Components/UserSearchAutocomplete";
import {
  ECABIN,
  checkNewSerialNumber,
  checkValidClientObject,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getLatestLifeCycleState,
  getLogoImage,
  getToken,
  getUser,
  readClientDataFromLCS,
  requestErrorHandler,
  resolveTransferLocation,
  transferStates,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
import MissingDevices from "./MissingDevices";
import ReadyForShipment from "./ReadyForShipment";
import TransferHistory from "./TransferHistory";

const server = EnvSettings.server;

const resolveTransferState = (data) => {
  if (data === "dispatched") {
    return "sold";
  }
  if (data === "maintenance") {
    return "return";
  }
  return data;
};

function ProductTransfers(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [transferData, setTransferData] = useState({
    state: "return",
    UserId: getUser().name !== "CTN Service" ? getUser().userId : undefined,
  });
  const [searchList, setSearchList] = useState();
  const [loading, setLoading] = useState(false);
  const [transferState, setTransferState] = useState(transferStates[0]);
  const [clientInfoObject, setClientInfoObject] = useState({});
  const [selectedTransfer, setSelectedTransfer] = useState();
  const [submitting, setSubmitting] = useState();
  const [showHistory, setShowHistory] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [acKey, setACKey] = useState("sdfdsfsd");
  const dispatch = useDispatch();
  const scrollref = useRef(null);

  const submitTransfer = () => {
    if (!transferData.UserId) {
      alert("Add user!");
      return;
    }
    if (!transferData.Device && !transferData.newDevice) {
      alert("Add Device!");
      return;
    }
    if (
      transferData.Device &&
      transferData.Device.LifeCycleStates.length &&
      resolveTransferState(
        getLatestLifeCycleState(transferData.Device.LifeCycleStates).state
      ) === transferData.state
    ) {
      alert("Device already returned!");
      return;
    }
    if (
      transferData.state === "sold" &&
      (!clientInfoObject ||
        !Object.keys(clientInfoObject).length ||
        !checkValidClientObject(clientInfoObject))
    ) {
      alert("Client information must be added!");
      return;
    }
    setSubmitting(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        clientInfo:
          transferData.state === "return" ? undefined : clientInfoObject,
        transferData: transferData,
      }),
    };
    fetch(server + "/add_transfer", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        // Reload on success
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          setSubmitting(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    const getTransfers = () => {
      setLoading(true);
      setSearchList();
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          limit: rowsPerPage,
        }),
      };
      fetch(server + "/get_device_transfers", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setSearchList(result.lcs);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    getTransfers();
  }, [rowsPerPage, dispatch, t]);

  const getCurrentLocation = (data) => {
    if (!data.Device.LifeCycleStates.length) {
      return "";
    }
    return t(
      "transferLocations." +
        (data.Device.LifeCycleStates.length
          ? getLatestLifeCycleState(data.Device.LifeCycleStates).location
          : "")
    );
  };

  const handleClose = () => {
    setSelectedTransfer();
  };

  return (
    <Grid container spacing={3}>
      <TransferHistory
        LCS={showHistory ? transferData.Device.LifeCycleStates : undefined}
        header={t("history")}
        handleClose={() => {
          setShowHistory(false);
        }}
      ></TransferHistory>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12}>
              <Title>{t("productTransfers.header")}</Title>
            </Grid>
            <Grid item xs={12}>
              <Typography>{t("newTransfer")}</Typography>
            </Grid>
            {/** scroll ref is placed on top the target because of the limitations of scrollintoview method */}
            <Grid item xs={12} sm={4} innerRef={scrollref}>
              <UserSearchAutocomplete
                defaultValue={
                  getUser().name !== "CTN Service" ? getUser().name : ""
                }
                required={true}
                onChange={(e, v) => {
                  if (v) {
                    setTransferData({ ...transferData, UserId: v.id });
                    return;
                  }
                  setTransferData({ ...transferData, UserId: undefined });
                }}
                userGroup={"CTN"}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {!transferData.Device && !transferData.newDevice ? (
                <DeviceSearchAutocomplete
                  scrollIntoView={() => {
                    // Delay is added because of the native scroll applied when the onscreen keyaboard pops up preventing this scroll from happening
                    setTimeout(() => {
                      scrollref.current.scrollIntoView();
                    }, 750);
                  }}
                  onChange={(e, v) => {
                    if (v && typeof v !== "string") {
                      if (v.inputValue) {
                        var x = checkNewSerialNumber(v.serialNumber);
                        if (x) {
                          alert(x);
                          setTransferData({
                            ...transferData,
                            Device: undefined,
                          });
                          setACKey(Date.now());
                          return;
                        }
                        setTransferData({
                          ...transferData,
                          newDevice: v.serialNumber,
                        });
                        return;
                      }

                      setTransferData({
                        ...transferData,
                        Device: v,
                      });
                      if (v?.LifeCycleStates.length) {
                        var z = JSON.parse(
                          JSON.stringify(
                            getLatestLifeCycleState(v.LifeCycleStates)
                              .clientInfo
                          )
                        );
                        setClientInfoObject(z);
                      }
                      return;
                    }
                    setTransferData({ ...transferData, device: undefined });
                  }}
                  allowNewDevices={true}
                  // This key hack allows to reset the element after bad input
                  key={acKey}
                />
              ) : null}
              {transferData.Device ? (
                <Grid item container xs={12}>
                  <Grid item sm={3} xs={6}>
                    <img
                      alt="Device"
                      src={getLogoImage(transferData.Device.serialNumber)}
                      style={{ maxWidth: 100, maxHeight: 100 }}
                    />
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <Typography>
                      {t("selectedDevice") +
                        ": " +
                        transferData.Device.serialNumber}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Typography>
                      {t("currentLocation") +
                        ": " +
                        getCurrentLocation(transferData)}
                    </Typography>
                    <Link
                      onClick={() => {
                        setShowHistory(true);
                      }}
                    >
                      {t("history")}
                    </Link>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setTransferData({ ...transferData, Device: undefined });
                        setClientInfoObject(undefined);
                      }}
                    >
                      {t("remove")}
                    </Button>
                  </Grid>
                </Grid>
              ) : transferData.newDevice ? (
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <img
                      alt="Device"
                      src={getLogoImage(transferData.newDevice)}
                      style={{ maxWidth: 100, maxHeight: 100 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {t("selectedDevice") + ": " + transferData.newDevice}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setTransferData({
                          ...transferData,
                          newDevice: undefined,
                        });
                      }}
                    >
                      {t("remove")}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <ToggleButtonGroup
                exclusive={true}
                onChange={(e, v) => {
                  if (!v) {
                    return;
                  }
                  setTransferState(v);
                  setTransferData({ ...transferData, state: v });
                }}
                variant="outlined"
                value={transferState}
              >
                {transferStates.map((s, i) => (
                  <ToggleButton
                    key={i}
                    value={s}
                    classes={{ selected: classes.selected }}
                  >
                    {t(s)}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={4}>
              {transferState !== "return" ? (
                <>
                  {transferState === "demo" ? (
                    <Typography style={{ fontSize: 13 }}>
                      *{t("productTransfers.leaveEmptyIfInCar")}
                    </Typography>
                  ) : null}
                  <BrowseContactsDialog
                    setClientInfoObject={setClientInfoObject}
                    activeObject={
                      clientInfoObject &&
                      Object.values(clientInfoObject).find((v) => v !== "")
                        ? clientInfoObject
                        : undefined
                    }
                  />
                  {clientInfoObject ? (
                    <ContactInfoBadge
                      clientInfoObject={clientInfoObject}
                      clearClientInfo={() => {
                        setClientInfoObject({});
                      }}
                    />
                  ) : null}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label={t("details")}
                type="text"
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  if (e.target) {
                    setTransferData({
                      ...transferData,
                      details: e.target.value,
                    });
                    return;
                  }
                  setTransferData({ ...transferData, details: undefined });
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={submitTransfer}
                endIcon={
                  submitting ? (
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  ) : null
                }
              >
                {t("send")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {t("clear")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} style={{ position: "relative" }}>
        <MissingDevices {...props} />
      </Grid>
      <Grid item xs={12} style={{ position: "relative" }}>
        <ReadyForShipment {...props} />
      </Grid>
      <Grid item xs={12}>
        <DeviceDialog
          data={selectedTransfer}
          handleClose={handleClose}
          header={t("edit")}
          allowDelete
        />
        <Paper className={classes.paper}>
          <Title>{t("latest") + " " + t("productTransfers.header")}</Title>
          {props.width === "xs" && searchList && searchList.length ? (
            searchList
              .sort((a, b) =>
                a.createdAt > b.createdAt
                  ? -1
                  : b.createdAt > a.createdAt
                  ? 1
                  : 0
              )
              .map((val, i) => (
                <DeviceStatusBadge
                  onClick={setSelectedTransfer}
                  data={val}
                  key={i}
                />
              ))
          ) : searchList ? (
            <SortableTable
              headCells={[
                {
                  id: "createdAt",
                  numeric: false,
                  disablePadding: false,
                  label: t("date"),
                },
                {
                  id: "state",
                  numeric: false,
                  disablePadding: false,
                  label: t("transferState"),
                },
                {
                  id: "location",
                  numeric: false,
                  disablePadding: false,
                  label: t("newLocation"),
                },
                {
                  id: "User",
                  numeric: false,
                  disablePadding: false,
                  label: t("representativeName"),
                },
                {
                  id: "Device.serialNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("serialNumber"),
                },
                {
                  id: "clientInfo",
                  numeric: false,
                  disablePadding: false,
                  label: t("clientInfo"),
                },
                {
                  id: "Notes",
                  numeric: false,
                  disablePadding: false,
                  label: t("sessionDetails.notes"),
                },
              ]}
              disablePagination={true}
              defaultSortRow={"createdAt"}
              handleRowClick={(e, v) => {
                setSelectedTransfer(e);
              }}
              // handleScrollClick = {showDeviceNewTab,
              tableData={searchList}
              rowsPerPage={rowsPerPage}
              specialDataFormatting={[
                {
                  id: "createdAt",
                  format: (d) => formatTimeWithTZOffset(d, ECABIN),
                },
                {
                  id: "state",
                  format: (d) => t(resolveTransferState(d)),
                },
                {
                  id: "location",
                  format: (d, dd, ddd) =>
                    t("transferLocations." + resolveTransferLocation(ddd)),
                },
                {
                  id: "User",
                  format: (d) => d?.fullName,
                },
                {
                  id: "clientInfo",
                  format: (d, dd, ddd) =>
                    formatClientInfoString(readClientDataFromLCS(ddd), [
                      "companyName",
                      "contactName",
                      "location",
                    ]),
                },
                {
                  id: "Notes",
                  format: (d) => d?.map((n) => n.text).join(" / "),
                },
              ]}
            />
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
          <Button
            variant="contained"
            onClick={() => {
              setRowsPerPage(rowsPerPage * 2);
            }}
          >
            {t("more")}
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withWidth()(ProductTransfers);
