import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
  resolveAlertType,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

export default function Alerts(props) {
  const classes = useStyles();
  //const [chartData, setChartData] = useState();
  const [recentAlerts, setRecentAlerts] = useState();
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  let getRecentAlerts = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken() }),
    };
    fetch(server + "/get_alerts", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setRecentAlerts(result.alerts);
        }
      });
  };

  let handleRowClick = (data) => {
    history.push("/cpanel/alerts/" + data.id);
  };

  useEffect(getRecentAlerts, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("alerts.header")}</Title>
          <FormControlLabel
            label={t("alerts.active") + "?"}
            defaultChecked={false}
            color="primary"
            onChange={() => {
              setShowOnlyActive(!showOnlyActive);
            }}
            control={<Checkbox color="primary"></Checkbox>}
          ></FormControlLabel>
          {recentAlerts ? (
            <SortableTable
              headCells={[
                {
                  id: "alertType",
                  numeric: false,
                  disablePadding: false,
                  label: t("alerts.alertType"),
                },
                {
                  id: "Device.serialNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("alerts.device"),
                },
                {
                  id: "message",
                  numeric: false,
                  disablePadding: false,
                  label: t("alerts.message"),
                },
                {
                  id: "UserId",
                  numeric: false,
                  disablePadding: false,
                  label: t("alerts.active"),
                },
                {
                  id: "updatedAt",
                  numeric: false,
                  disablePadding: false,
                  label: t("alerts.updatedAt"),
                },
              ]}
              defaultSortRow={"updatedAt"}
              handleRowClick={handleRowClick}
              tableData={recentAlerts.filter((f) => {
                if (!showOnlyActive) {
                  return f;
                }
                return !f.UserId;
              })}
              rowsPerPage={200}
              specialDataFormatting={[
                {
                  id: "alertType",
                  format: (data) => {
                    return resolveAlertType(data);
                  },
                },
                {
                  id: "UserId",
                  format: (d) => {
                    return d ? t("no") : t("yes");
                  },
                },
                {
                  id: "updatedAt",
                  format: (data) => {
                    return formatTimeWithTZOffset(data, ECABIN);
                  },
                },
              ]}
            />
          ) : (
            <CircularProgress />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
