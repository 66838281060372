import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SortableTable from "../../../Components/SortableTable";
import TicketEntry from "../../../Components/TicketEntry";
import Title from "../../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
  ticketStates,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
const server = EnvSettings.server;

export default function MaintenanceTickets(props) {
  const { t } = useTranslation();
  const [showTicketEntry, setShowTicketEntry] = useState(false);
  const [refreshList, setRefreshList] = useState("dsfsdfds");
  const [tickets, setTickets] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [filteredList, setFilteredList] = useState();
  const [searchVal, setSearchVal] = useState();
  const [filterStates, setFilterStates] = useState([
    ...ticketStates.filter((o) => o.value !== "resolved"),
  ]);

  const dispatch = useDispatch();

  let handleRowClick = (data) => {
    if (data) {
      setSelectedTicket(data);
      setShowTicketEntry(true);
      // setShowTicketEntry(false);
      // setTimeout(() => {
      //   setShowTicketEntry(true);
      // }, 1);
    }
  };

  const handleScrollClick = (event, id) => {};

  useEffect(() => {
    setTickets();
    const getTickets = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
        }),
      };
      fetch(server + "/get_tickets", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (result.tickets.length !== 0) {
              setTickets(result.tickets);
            }
          }
        });
    };
    getTickets();
  }, [dispatch, refreshList]);

  // useEffect(() => {
  //   if (showTicketEntry === false) {
  //     setRefreshList(Date.now());
  //   }
  // }, [setRefreshList, showTicketEntry]);

  useEffect(() => {
    if ((searchVal || filterStates.length) && tickets) {
      var arr = tickets.filter((ti) => {
        if (!ti) {
          return false;
        }
        if (searchVal && filterStates.length) {
          return (
            !!Object.keys(ti).find(
              (k) =>
                ti[k] &&
                String(ti[k]).toLowerCase().includes(searchVal.toLowerCase())
            ) && filterStates.find((s) => ti.state === s.value)
          );
        } else if (searchVal) {
          return !!Object.keys(ti).find(
            (k) =>
              ti[k] &&
              String(ti[k]).toLowerCase().includes(searchVal.toLowerCase())
          );
        }
        return filterStates.find((s) => ti.state === s.value);
      });

      setFilteredList(arr);
    } else {
      setFilteredList(tickets);
    }
  }, [searchVal, tickets, filterStates]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Title>{t("deviceDetails.maintenanceTickets")}</Title>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedTicket();
            setShowTicketEntry(true);
          }}
        >
          {t("newTicket")} +
        </Button>
        <Grid item>
          {ticketStates.map((o) => (
            <FormControlLabel
              key={o.label}
              defaultChecked={true}
              control={
                <Checkbox
                  color="primary"
                  onChange={(e, v) => {
                    if (v) {
                      setFilterStates([...filterStates, o]);
                      return;
                    }
                    setFilterStates([...filterStates.filter((f) => f !== o)]);
                  }}
                  defaultChecked={o.value !== "resolved"}
                />
              }
              label={t("ticketList." + o.value)}
            />
          ))}
        </Grid>
        <TextField
          style={{ marginTop: 10 }}
          variant="outlined"
          label={t("search")}
          fullWidth
          onChange={(e) => {
            setSearchVal(e.target.value);
          }}
        />
        <TicketEntry
          showTicketEntry={showTicketEntry}
          setShowTicketEntry={setShowTicketEntry}
          selectedTicket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
          showHeader={true}
          showSearch={true}
          setRefreshList={setRefreshList}
        />
        {filteredList ? (
          <SortableTable
            headCells={[
              {
                id: "Device.serialNumber",
                numeric: false,
                disablePadding: false,
                label: t("serialNumber"),
              },
              {
                id: "description",
                numeric: false,
                disablePadding: false,
                label: t("ticketList.description"),
              },
              {
                id: "action",
                numeric: false,
                disablePadding: false,
                label: t("ticketList.action"),
              },
              {
                id: "details",
                numeric: false,
                disablePadding: false,
                label: t("ticketList.details"),
              },
              {
                id: "state",
                numeric: false,
                disablePadding: false,
                label: t("ticketList.state"),
              },
              {
                id: "User.fullName",
                numeric: false,
                disablePadding: false,
                label: t("ticketList.editor"),
              },
              {
                id: "createdAt",
                numeric: false,
                disablePadding: false,
                label: t("createdAt"),
              },
              {
                id: "updatedAt",
                numeric: false,
                disablePadding: false,
                label: t("updatedAt"),
              },
            ]}
            getRowStyle={(data) => {
              var obj = { cursor: "pointer" };
              if (data.state === "new") {
                return { ...obj, backgroundColor: "lightcoral" };
              }
              if (data.state === "resolved") {
                return { ...obj, backgroundColor: "lightgreen" };
              }
              if (data.state === "progress") {
                return { ...obj, backgroundColor: "lightblue" };
              }
              return obj;
            }}
            defaultSortRow={"updatedAt"}
            handleRowClick={handleRowClick}
            handleScrollClick={handleScrollClick}
            maxDataLength={30}
            tableData={filteredList}
            rowsPerPage={200}
            downloadButton={true}
            header={t("deviceDetails.maintenanceTickets")}
            specialDataFormatting={[
              {
                id: "state",
                format: (data) => {
                  return t("ticketList." + data);
                },
              },
              {
                id: "createdAt",
                format: (data) => {
                  return formatTimeWithTZOffset(data, ECABIN);
                },
              },
              {
                id: "updatedAt",
                format: (data) => {
                  return formatTimeWithTZOffset(data, ECABIN);
                },
              },
            ]}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
}
