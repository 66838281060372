import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslation } from "react-i18next";

import Build from "@material-ui/icons/Build";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TimelineIcon from "@material-ui/icons/Timeline";
import BadgeLink from "../../Components/BadgeLink";
import Title from "../../Components/Title";
import { getUser, USER_GROUPS } from "../../Utils/Common";

export default function MaintenanceModule() {
  const { t } = useTranslation();
  const badgeLinks = [
    {
      href: "/cpanel/maintenance/tickets",
      title: t("deviceDetails.maintenanceTickets"),
      icon: Build,
      superuser: true,
    },
    {
      href: "/cpanel/maintenance/statistics",
      title: t("maintenance.statistics"),
      icon: TimelineIcon,
    },
    {
      href: "/cpanel/alerts",
      title: t("alerts.header"),
      icon: NotificationsIcon,
    },
  ];

  return (
    <Grid container justify="center">
      <Grid container item xs={12} justify="center">
        <Grid item>
          <Title>{t("maintenance.header")}</Title>
        </Grid>
      </Grid>
      <Grid container spacing={3} justify="center" item md={12} lg={6}>
        {/* All the admin items as badges */}
        {badgeLinks
          .sort((a, b) => {
            return a.title > b.title ? 1 : -1;
          })
          .map((b, i) =>
            b.superuser &&
            getUser().permissionlevel !== USER_GROUPS.SUPER_USER ? null : (
              <BadgeLink key={i} href={b.href} title={b.title} icon={b.icon} />
            )
          )}
      </Grid>
    </Grid>
  );
}
