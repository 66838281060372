import {
  CircularProgress,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
} from "@material-ui/core";
import ArrowDropDownCircle from "@material-ui/icons/ArrowDropDownCircle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DateSelect from "../../../Components/DateSelect";
import ExpenseHeader from "../../../Components/TicketEntry/ExpenseHeader";
import ExpenseItem from "../../../Components/TicketEntry/ExpenseItem";
import Title from "../../../Components/Title";
import { getToken, requestErrorHandler } from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";
const server = EnvSettings.server;

function ExpenseSegment({ expenseKey, tickets, date }) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const dateFilter = (data) => {
    return data.filter((f) => {
      if (!date) {
        return f;
      }
      var dt = Date.parse(f.createdAt);
      if (date.startDate && date.endDate) {
        return (
          dt >= Date.parse(date.startDate) && dt <= Date.parse(date.endDate)
        );
      } else if (date.startDate) {
        return dt >= Date.parse(date.startDate);
      }
      return dt <= Date.parse(date.endDate);
    });
  };

  const getStats = (data, key) => {
    return data.reduce((prev, curr) => {
      if (!curr.expenses) {
        return prev;
      }
      return (
        prev +
        Object.keys(curr.expenses).reduce((prev2, k) => {
          if (key && k !== key) {
            return prev2;
          }
          return (
            curr.expenses[k].reduce(
              (prev2, curr) =>
                Number(curr.price) * (curr.count ? Number(curr.count) : 1) +
                prev2,
              0
            ) + prev2
          );
        }, 0)
      );
    }, 0);
  };

  const getItems = (data, key) => {
    return data
      .filter((f) => {
        if (!f.expenses || (key && !f.expenses[key])) {
          return false;
        }
        return true;
      })
      .map((curr) => {
        return Object.keys(curr.expenses).map((k) => {
          if (key && k !== key) {
            return null;
          }
          return curr.expenses[k].map((m, i) => (
            <ExpenseItem key={m + i} obj={m}></ExpenseItem>
          ));
        });
      });
  };
  return (
    <Grid item xs={12}>
      <>
        <ListItem
          button
          onClick={() => {
            setShow(!show);
          }}
        >
          <ListItemIcon>
            <ArrowDropDownCircle style={show ? { rotate: "180deg" } : {}} />
          </ListItemIcon>
          <Typography>
            {t("ticketList." + expenseKey)}{" "}
            {getStats(dateFilter(tickets), expenseKey)}€
          </Typography>
        </ListItem>
        {show ? (
          <Grid item xs={12}>
            <ExpenseHeader></ExpenseHeader>
            {getItems(dateFilter(tickets), expenseKey)}
          </Grid>
        ) : null}
      </>
      <Divider></Divider>
    </Grid>
  );
}

const expenseKeys = [
  "spareParts",
  "shippingCosts",
  "outsourcedServices",
  "travelCosts",
];

export default function MaintenanceStatistics(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tickets, setTickets] = useState();
  const [date, setDate] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setTickets();
    const getTickets = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
        }),
      };
      fetch(server + "/get_tickets", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (result.tickets.length !== 0) {
              setTickets(result.tickets);
            }
          }
        });
    };
    getTickets();
  }, [dispatch]);

  const dateFilter = (data) => {
    return data.filter((f) => {
      if (!date) {
        return f;
      }
      var dt = Date.parse(f.createdAt);
      if (date.startDate && date.endDate) {
        return (
          dt >= Date.parse(date.startDate) && dt <= Date.parse(date.endDate)
        );
      } else if (date.startDate) {
        return dt >= Date.parse(date.startDate);
      }
      return dt <= Date.parse(date.endDate);
    });
  };

  const getStats = (data, key) => {
    return data.reduce((prev, curr) => {
      if (!curr.expenses) {
        return prev;
      }
      return (
        prev +
        Object.keys(curr.expenses).reduce((prev2, k) => {
          if (key && k !== key) {
            return prev2;
          }
          return (
            curr.expenses[k].reduce(
              (prev2, curr) =>
                Number(curr.price) * (curr.count ? Number(curr.count) : 1) +
                prev2,
              0
            ) + prev2
          );
        }, 0)
      );
    }, 0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Title>{t("maintenance.statistics")}</Title>
            </Grid>
            {tickets ? (
              <Grid item xs={6} container spacing={1}>
                <Grid item xs={12}>
                  <DateSelect
                    date={date}
                    setDate={setDate}
                    hideTime={true}
                    noPaper={true}
                  ></DateSelect>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Typography>
                    {t("maintenance.numberTickets")}
                    {": "}
                    {dateFilter(tickets).length}
                  </Typography>
                  <Typography>
                    {t("maintenance.withExpenses")}
                    {": "}
                    {dateFilter(tickets).filter((f) => f.expenses).length}
                  </Typography>
                </Grid>
                {expenseKeys.map((m) => (
                  <ExpenseSegment
                    key={m}
                    expenseKey={m}
                    tickets={tickets}
                    date={date}
                  />
                ))}
                <Grid item xs={12}>
                  <ListItem>
                    <Typography>
                      Total {getStats(dateFilter(tickets))} €
                    </Typography>
                  </ListItem>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
