import {
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React from "react";

export default function ExpenseItem({ obj, onClick }) {
  return (
    <>
      <Divider></Divider>
      <ListItem dense>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6}>
            {obj.name}
          </Grid>
          <Grid item xs={2}>
            {obj.price + "€"}
          </Grid>
          <Grid item xs={3}>
            {obj.count && obj.count > 1
              ? "x" + obj.count + " = " + obj.price * obj.count + "€"
              : ""}
          </Grid>
          <Grid item xs={1}>
            {onClick ? (
              <ListItemIcon>
                <IconButton size="small" onClick={onClick}>
                  <Delete />
                </IconButton>
              </ListItemIcon>
            ) : null}
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
}
