import { Grid, ListItem, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ExpenseHeader() {
  const { t } = useTranslation();
  return (
    <ListItem dense>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={6}>
          <Typography style={{ fontSize: 13 }}>{t("name")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography style={{ fontSize: 13 }}>
            {t("stockModule.costPrice")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ fontSize: 13 }}>Total</Typography>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </ListItem>
  );
}
