import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
} from "../../../Utils/Common";

import EnvSettings from "../../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../../redux/actions/snackbarActions";

const server = EnvSettings.server;

const linearRegression = (y, x) => {
  var arr = [...y];
  var lr = {
    last_resupply: 0,
    slope: 0,
    intercept: 0,
    r2: 0,
    zero_index: -1,
  };
  if (!arr[arr.length - 1].free) {
    return lr;
  }
  var total_length = y.length;
  if (total_length < 3) {
    return lr;
  }
  var last_resupply = 0;
  var resupply_threshold = 5;
  arr.forEach((f, i) => {
    if (i) {
      if (y[i - 1].free && f.free >= y[i - 1].free + resupply_threshold) {
        last_resupply = i;
      }
    }
  });
  if (arr[arr.length - 1].projection && !arr[arr.length - 1].free) {
    arr.pop();
  }
  // sort function sort y permanently, we need to make a copy
  y = arr.slice(last_resupply);
  x = y.map((m, i) => i);
  if (
    y[y.length - 1].free &&
    Date.now() - Date.parse(y[y.length - 1].createdAt) > 60 * 60 * 24 * 3 * 1000
  ) {
    // Add a duplicate to the end
    var obj = y[y.length - 1];
    obj.createdAt = new Date().toISOString();
    y.push(obj);
    x.push(x.length);
  }
  y = y.map((m) => m.free);

  lr["last_resupply"] = last_resupply;
  var n = y.length;
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var sum_yy = 0;

  for (var i = 0; i < y.length; i++) {
    sum_x += x[i];
    sum_y += y[i];
    sum_xy += x[i] * y[i];
    sum_xx += x[i] * x[i];
    sum_yy += y[i] * y[i];
  }
  lr["slope"] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
  lr["intercept"] = (sum_y - lr.slope * sum_x) / n;
  lr["r2"] = Math.pow(
    (n * sum_xy - sum_x * sum_y) /
      Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
    2
  );
  var idx = y.length - 1;
  var last_val = lr.slope * (total_length - 1) + lr.intercept;
  if (last_val < 0) {
    lr.intercept = lr.intercept + y[idx] + Math.abs(last_val);
  } else {
    lr.intercept = lr.intercept + (y[idx] - Math.abs(last_val));
  }
  last_val = y[y.length - 1];
  while (last_val > 0.0) {
    if (lr.slope >= 0) {
      break;
    }
    total_length = total_length + 1;
    last_val = lr.slope * (total_length - 1) + lr.intercept;
    if (!Math.round(last_val)) {
      break;
    }
  }
  if (lr.slope >= 0) {
    return {
      last_resupply: 0,
      slope: 0,
      intercept: 0,
      r2: 0,
      zero_index: -1,
    };
  }
  lr["zero_index"] = total_length - 1;
  return lr;
};

const findZeroDate = (idx, idx_start, arr) => {
  if (idx === -1) {
    return "--";
  }
  var _arr = [...arr];
  if (idx_start) {
    _arr = [...arr].splice(idx_start);
  }
  var start = Date.parse(_arr[0].createdAt);
  var end = Date.parse(_arr[_arr.length - 1].createdAt);
  var step = (end - start) / _arr.length;
  var dat = new Date(Math.round(step * idx + start)).toISOString();
  return formatTimeWithTZOffset(dat, ECABIN).substring(0, 10);
};

export default function GraphDialog({ show, setShow, edit }) {
  const dispatch = useDispatch();
  const [stockChanges, setStockChanges] = useState([]);
  const [linearR, setLinearR] = useState();
  const { t } = useTranslation();
  const theme = useTheme();

  const handleExit = () => {
    setShow(false);
  };

  const getArray = () => {
    var arr3 = [];
    if (linearR.zero_index >= 0) {
      var arr2 = [...Array(linearR.zero_index - (stockChanges.length - 1))];
      arr2.forEach((c, i) => {
        if (i > 10) {
          return;
        }
        arr3.push({
          createdAt: findZeroDate(
            linearR.zero_index,
            linearR.last_resupply,
            stockChanges
          ),
          free: null,
        });
      });
    }
    var arr = [
      ...stockChanges.sort((a, b) => {
        return new Date(
          b.projection || b.createdAt.substring(0, 10)
        ).getTime() >
          new Date(a.projection || a.createdAt.substring(0, 10)).getTime()
          ? -1
          : 1;
      }),
      ...arr3,
    ];
    return arr;
  };

  useEffect(() => {
    const getStockChanges = () => {
      if (!edit || !edit.StockItem || !edit.Warehouse) {
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          stock_item_id: edit.StockItem.id,
          warehouse_id: edit.Warehouse.id,
        }),
      };
      fetch(server + "/get_stock_changes", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setLinearR(linearRegression(result.stock));
            setStockChanges(result.stock);
            if (!result.stock.length) {
              setShow(false);
              dispatch(
                showPopupSnackbar({
                  error: true,
                  message: "No stock information",
                })
              );
            }
            // setTagList(result.stock);
          }
        });
    };
    if (show && !stockChanges.length) {
      getStockChanges();
    }
    // eslint-disable-next-line
  }, [show, stockChanges, setStockChanges, dispatch]);

  return stockChanges.length && linearR ? (
    <Dialog open={show} onExit={handleExit}>
      <DialogContent>
        <Grid
          item
          xs={12}
          style={{ overflow: "hidden", width: 500, height: 500 }}
        >
          <ResponsiveContainer>
            <ComposedChart
              data={getArray().map((s, i) => {
                return {
                  timeStamp: s.projection || s.createdAt.substring(0, 10),
                  free: s.free,
                  total: s.projection ? null : s.total,
                  projection: s.projection ? s.total : null,
                  linearR: i * linearR.slope + linearR.intercept,
                };
              })}
              margin={{
                top: 16,
                right: 16,
                bottom: 0,
                left: 24,
              }}
            >
              <XAxis
                dataKey="timeStamp"
                stroke={theme.palette.text.secondary}
              />
              <YAxis stroke={theme.palette.text.secondary}>
                <Label
                  angle={270}
                  position="left"
                  style={{
                    textAnchor: "middle",
                    fill: theme.palette.text.primary,
                  }}
                >
                  {t("stockModule.stock")}
                </Label>
              </YAxis>
              <Tooltip />
              <Line dot={false} dataKey={"free"} stroke={"red"} connectNulls />
              <Line
                dot={false}
                dataKey={"total"}
                stroke={"blue"}
                connectNulls
              />
              <Line
                dot={false}
                dataKey={"linearR"}
                stroke={"orange"}
                strokeDasharray="3 3"
                connectNulls
              />
              <Line dot={true} dataKey={"projection"} stroke={"black"} />
              <ReferenceLine y={0} label={"Empty"} stroke={"black"} />
              {edit && edit.alertQuantity ? (
                <ReferenceLine
                  y={edit.alertQuantity}
                  label={t("stockModule.alertQuantity")}
                  stroke="blue"
                  strokeDasharray="3 3"
                />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        </Grid>
        {linearR &&
        stockChanges &&
        stockChanges.length > 2 &&
        stockChanges[stockChanges.length - 1].free ? (
          <Typography style={{ whiteSpace: "pre-wrap" }}>
            {t("stockModule.estimatedZeroDate") + ":\n"}
            {findZeroDate(
              linearR.zero_index,
              linearR.last_resupply,
              stockChanges
            )}
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setShow(false);
          }}
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
