import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "../Utils/Styles";

function Selector({ setLimit, setDate, date, selectedDate, hideTime }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      {setLimit ? (
        <Grid item xs={12} sm={4}>
          <TextField
            type="number"
            defaultValue={500}
            label={t("dateSelect.limit")}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
            fullWidth
          />
        </Grid>
      ) : null}
      <Grid item xs={12} sm={4}>
        <TextField
          id="rangeStartDate"
          label={t("dateSelect.rangeStartDate")}
          type="date"
          defaultValue={selectedDate?.substring(0, 10)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setDate({ ...date, startDate: e.target.value });
          }}
          fullWidth
        />
        {hideTime ? null : (
          <TextField
            id="rangeStartTime"
            label={t("dateSelect.rangeStartTime")}
            type="time"
            className={classes.textField}
            style={{ marginTop: 5 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setDate({ ...date, startTime: e.target.value });
            }}
            fullWidth
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          id="rangeEndDate"
          label={t("dateSelect.rangeEndDate")}
          type="date"
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setDate({ ...date, endDate: e.target.value });
          }}
          fullWidth
        />
        {hideTime ? null : (
          <TextField
            id="rangeEndTime"
            label={t("dateSelect.rangeEndTime")}
            type="time"
            className={classes.textField}
            style={{ marginTop: 5 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setDate({ ...date, endTime: e.target.value });
            }}
            fullWidth
          />
        )}
      </Grid>
    </Grid>
  );
}

export default function DateSelect({
  setLimit,
  setDate,
  date,
  selectedDate,
  hideTime,
  noPaper,
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      {noPaper ? (
        <Selector
          setLimit={setLimit}
          setDate={setDate}
          date={date}
          selectedDate={selectedDate}
          hideTime={hideTime}
        />
      ) : (
        <Paper
          className={classes.paper}
          style={{
            alignItems: "center",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Selector
            setLimit={setLimit}
            setDate={setDate}
            date={date}
            selectedDate={selectedDate}
            hideTime={hideTime}
          />
        </Paper>
      )}
    </Grid>
  );
}
