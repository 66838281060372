import { Button, Checkbox, IconButton, TableFooter } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Delete from "@material-ui/icons/Delete";
import GetApp from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatGoogleDriveMediaLink } from "../Utils/Common";

function descendingComparator(aa, bb, orderBy) {
  var a;
  var b;
  orderBy = orderBy || "";
  orderBy.split(".").forEach((o, i) => {
    if (!i) {
      a = aa[o];
      b = bb[o];
    } else {
      try {
        a = a[o];
      } catch (error) {
        a = "";
      }
      try {
        b = b[o];
      } catch (error) {
        b = "";
      }
    }
  });
  if (a === null || a === undefined) {
    a = "";
  }
  if (b === null || b === undefined) {
    b = "";
  }
  if (!a && isNaN(a) && b) {
    return -1;
  }
  if (a && !b && isNaN(b)) {
    return 1;
  }
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells
          ? headCells
              .filter((m) => m.id)
              .map((headCell, i) => (
                <TableCell
                  key={headCell.id + i}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))
          : undefined}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
};

function EnhancedTableRow({
  row,
  headCells,
  imageData,
  handleRowClick,
  specialDataFormatting,
  maxDataLength,
  checkBox,
  onRowHover,
  handleScrollClick,
  getRowStyle,
  deleteButton,
}) {
  if (!headCells) {
    return null;
  }
  var arr = [];
  headCells
    .filter((m) => m.id)
    .forEach((c, i) => {
      var d = "";
      var dd = "";
      try {
        var x = c.id.split(".");
        x.forEach((xx, i) => {
          if (!i) {
            d = row[xx];
          } else {
            d = d[xx];
          }
        });
      } catch (error) {}
      if (imageData) {
        var y = imageData.split(".");
        y.forEach((yy, ii) => {
          if (!ii) {
            dd = row[yy];
          } else {
            dd = dd[yy];
          }
        });
        dd = formatGoogleDriveMediaLink(dd);
      }
      arr.push(
        <TableCell
          key={c.id + i}
          onClick={(e) => {
            handleRowClick(row);
          }}
        >
          {specialDataFormatting &&
          specialDataFormatting.find((obj) => obj.id === c.id) ? (
            specialDataFormatting
              .find((obj) => obj.id === c.id)
              .format(d, dd, row)
          ) : maxDataLength ? (
            <>
              {d && d.length > maxDataLength
                ? d.substring(0, maxDataLength) + "..."
                : d}
            </>
          ) : (
            <>{d}</>
          )}
        </TableCell>
      );
    });
  if (checkBox) {
    arr.push(
      <TableCell>
        <Checkbox
          color="primary"
          style={{ padding: 0 }}
          onChange={
            typeof checkBox === "function"
              ? (e, v) => {
                  checkBox(e, v, row);
                }
              : () => {}
          }
        />
      </TableCell>
    );
  }
  if (deleteButton) {
    arr.push(
      <TableCell>
        <IconButton
          size="small"
          onClick={typeof deleteButton === "function" ? deleteButton : () => {}}
        >
          <Delete />
        </IconButton>
      </TableCell>
    );
  }

  return (
    <TableRow
      hover
      onMouseOver={() => {
        if (onRowHover) {
          onRowHover(row);
        } else return;
      }}
      onMouseDown={(e) => {
        if (e.nativeEvent.button === 1 && handleScrollClick) {
          handleScrollClick(e, row);
        }
      }}
      style={getRowStyle ? getRowStyle(row) : { cursor: "pointer" }}
    >
      {arr}
    </TableRow>
  );
}

/**
 *
 * @param {Array} headCells - Array of objects
 * @param {Boolean} disablePagination
 * @param {String} tableSize
 * @param {Array} tableData
 * @param {Boolean} deleteButton
 * @param {Boolean | Function} checkBox
 * @param {Number} rowsPerPage
 * @param {String} defaultOrder
 * @param {String} defaultSortRow
 * @param {Boolean} downloadButton
 * @param {Function} onRowHover
 * @param {Function} handleRowClick
 * @param {Function} handleScrollClick
 * @param {Function} getRowStyle
 * @param {String} imageData
 * @param {Array<JSON>} specialDataFormatting
 * @param {Number} maxDataLength
 * @param {String} header
 */
export default function SortableTable({
  headCells,
  disablePagination,
  tableSize,
  tableData,
  deleteButton,
  checkBox,
  rowsPerPage,
  defaultOrder,
  defaultSortRow,
  downloadButton,
  onRowHover,
  handleRowClick,
  handleScrollClick,
  getRowStyle,
  imageData,
  specialDataFormatting,
  maxDataLength,
  header,
}) {
  const [page, setPage] = useState(0);
  tableSize = tableSize || "medium";
  const [_rowsPerPage, setRowsPerPage] = useState(rowsPerPage || 10);
  const [order, setOrder] = useState(defaultOrder || "desc");
  const [orderBy, setOrderBy] = useState(defaultSortRow);
  const { t } = useTranslation();

  function downloadJSONAsCSV(_data) {
    // Fetch JSON data from the endpoint
    // Convert JSON data to CSV

    if (!_data.tableData.length) {
      alert("No records");
      return;
    }
    let csvData = jsonToCsv(_data); // Add .items.data
    // Create a CSV file and allow the user to download it
    let blob = new Blob([csvData], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = (_data.header || "table-" + new Date().toISOString()) + ".csv";
    document.body.appendChild(a);
    a.click();
  }

  function jsonToCsv(jsonData) {
    let csv = "";
    var _headCells = jsonData.headCells.filter((m) => m.id);
    var _data = jsonData.tableData;
    // Get the headers
    _headCells.forEach((h, idx) => {
      csv += h.label + (idx === _headCells.length - 1 ? "" : ";");
    });
    csv += "\n";
    _data.forEach((a) => {
      _headCells.forEach((h, idx) => {
        var d = "";
        try {
          var x = h.id.split(".");
          x.forEach((xx, i) => {
            if (!i) {
              d = a[xx];
            } else {
              d = d[xx];
              // if (xx === "StockItemGroups") {
              //   d = d.map((f) => f.StockItemGroupName.name).join(",");
              // }
            }
          });
        } catch (error) {}
        if (jsonData.specialDataFormatting) {
          var o = jsonData.specialDataFormatting.find((obj) => {
            return obj.id === h.id;
          });
          if (o) {
            d = o.format(d, "json");
          }
        }
        if (!d && d !== 0) {
          d = "";
        }
        try {
          d = d.replaceAll(";", "").replaceAll("\n", "");
        } catch (error) {}
        csv += d + (idx === _headCells.length - 1 ? "" : ";");
      });
      csv += "\n";
    });
    return csv;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {downloadButton ? (
        <Button
          style={{ width: 135, marginTop: 5 }}
          variant="outlined"
          onClick={() => {
            downloadJSONAsCSV({
              headCells,
              disablePagination,
              tableSize,
              tableData,
              checkBox,
              rowsPerPage,
              defaultOrder,
              defaultSortRow,
              downloadButton,
              onRowHover,
              handleRowClick,
              handleScrollClick,
              getRowStyle,
              imageData,
              specialDataFormatting,
              maxDataLength,
              header,
            });
          }}
          endIcon={<GetApp />}
        >
          {t("download") + " .csv"}
        </Button>
      ) : null}
      <TableContainer>
        <Table size={tableSize}>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            //
          />
          <TableBody>
            {tableData
              ? stableSort(tableData, getComparator(order, orderBy))
                  .slice(
                    page * _rowsPerPage,
                    page * _rowsPerPage + _rowsPerPage
                  )
                  .map((row, index) => (
                    <EnhancedTableRow
                      key={index}
                      row={row}
                      onRowHover={onRowHover}
                      handleRowClick={handleRowClick}
                      handleScrollClick={handleScrollClick}
                      getRowStyle={getRowStyle}
                      headCells={headCells}
                      imageData={imageData}
                      specialDataFormatting={specialDataFormatting}
                      maxDataLength={maxDataLength}
                      checkBox={checkBox}
                      deleteButton={deleteButton}
                    />
                  ))
              : undefined}
          </TableBody>
          {disablePagination ? undefined : (
            <TableFooter>
              <TableRow>
                <TableCell>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100, 200]}
                    component="div"
                    count={tableData ? tableData.length : 0}
                    rowsPerPage={_rowsPerPage}
                    labelRowsPerPage={t("sortableTable.rowsPerPage")}
                    labelDisplayedRows={({ from, to, count }) => {
                      return `${from}-${to} / ${count}`;
                    }}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    style={{ overflow: "visible" }}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
