import {
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getLastParameter,
  getToken,
  requestErrorHandler,
  resolveAlertType,
  sensorOptions,
  skipAlertOptions,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";

const server = EnvSettings.server;

function SkipAlerts(props) {
  const [selectedAlertSkip, setSelectedAlertSkip] = useState("");
  const [alertSkipDetails, setAlertSkipDetails] = useState("");
  const [deviceConfig, setDeviceConfig] = useState();
  const [itemDeleted, setItemDeleted] = useState(false);
  const device_id = props.device_id || getLastParameter();
  const dispatch = useDispatch();

  const getDeviceConfigs = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken(), deviceid: device_id }),
    };
    fetch(server + "/get_device_config", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setDeviceConfig(result.deviceconfig ?? {});
        }
      });
  };

  const editDeviceConfig = () => {
    if ((!selectedAlertSkip || !alertSkipDetails) && !itemDeleted) {
      return;
    }
    var n =
      selectedAlertSkip.value === "long_precool"
        ? selectedAlertSkip.type + "" + 1
        : selectedAlertSkip.type + "" + alertSkipDetails.value;
    var newVal = selectedAlertSkip
      ? { [n]: { value: alertSkipDetails.value } }
      : null;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: device_id,
        skipAlerts: {
          ...deviceConfig?.skipAlerts,
          ...newVal,
        },
      }),
    };
    fetch(server + "/edit_device_config", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setAlertSkipDetails();
          setSelectedAlertSkip();
          setDeviceConfig();
          getDeviceConfigs();
        }
      });
  };

  useEffect(() => {
    getDeviceConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return deviceConfig ? (
    <Grid item container xs={12} spacing={1}>
      <Grid xs={12}>
        <Title>Alert Settings</Title>
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={"Skip alerts"}
          select
          fullWidth
          value={selectedAlertSkip?.value ?? ""}
          variant="outlined"
          onChange={(e, v) => {
            setSelectedAlertSkip(
              skipAlertOptions.find((o) => o.value === e.target.value)
            );
          }}
        >
          {skipAlertOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {selectedAlertSkip?.value === "long_precool" ? (
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            label={"Duration threshold"}
            placeholder="Duration after which the alerts are created, 0=Always, 10800=Never"
            type="number"
            inputProps={{ min: 0, max: 10800 }}
            onChange={(e, v) => {
              setAlertSkipDetails(
                e.target.value ? { value: parseInt(e.target.value) } : ""
              );
            }}
          ></TextField>
        </Grid>
      ) : null}
      {selectedAlertSkip?.value === "failed_sensor" ? (
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            label={"Sensor Number"}
            select
            onChange={(e, v) => {
              setAlertSkipDetails(
                e.target.value ? { value: parseInt(e.target.value) } : ""
              );
            }}
          >
            {sensorOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}
      {selectedAlertSkip?.value === "failed_session" ? (
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            label={"Session Type"}
            select
            onChange={(e, v) => {
              setAlertSkipDetails(
                e.target.value ? { value: parseInt(e.target.value) } : ""
              );
            }}
          >
            {[
              { value: 1, label: "Precool" },
              { value: 2, label: "Session" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}
      {selectedAlertSkip?.value === "failed_compressor" ? (
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            label={"Compressor"}
            select
            onChange={(e, v) => {
              setAlertSkipDetails(
                e.target.value ? { value: parseInt(e.target.value) } : ""
              );
            }}
          >
            {[
              { value: 1, label: "Left" },
              { value: 2, label: "Right" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography>Current settings</Typography>
      </Grid>
      {deviceConfig?.skipAlerts ? (
        <Grid
          item
          xs={12}
          style={{ border: "1px solid lightgray", borderRadius: 5 }}
        >
          <Grid item>
            <Typography>Skip Alerts</Typography>
          </Grid>
          {Object.keys(deviceConfig.skipAlerts).map((k, i) => (
            <Grid item key={i}>
              <Typography>
                {resolveAlertType(k)} : {deviceConfig.skipAlerts[k].value}
                <IconButton
                  onClick={() => {
                    var sA = { ...deviceConfig.skipAlerts };
                    delete sA[k];
                    setDeviceConfig({
                      ...deviceConfig,
                      skipAlerts: sA,
                    });
                    setItemDeleted(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Typography>
            </Grid>
          ))}
        </Grid>
      ) : null}
      {(selectedAlertSkip && alertSkipDetails) || itemDeleted ? (
        <Grid item xs={12}>
          <Button variant="outlined" onClick={editDeviceConfig}>
            Submit
          </Button>
        </Grid>
      ) : null}
    </Grid>
  ) : null;
}

export default function Alerts(props) {
  const [recentAlerts, setRecentAlerts] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedAlerts, setSelectedAlerts] = useState();
  const device_id = props.device_id || getLastParameter();

  let getRecentAlerts = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken(), deviceid: device_id }),
    };
    fetch(server + "/get_alerts", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setRecentAlerts(result.alerts);
        }
      });
  };

  const handleAlerts = () => {
    if (!selectedAlerts || !selectedAlerts.length) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        alert_id: selectedAlerts,
      }),
    };
    fetch(server + "/handle_alert", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          window.location.reload();
        }
      });
  };

  let handleRowClick = (data) => {
    history.push("/cpanel/alerts/" + data.id);
  };

  useEffect(getRecentAlerts, []);

  return (
    <Grid container spacing={3}>
      <SkipAlerts />
      {selectedAlerts && selectedAlerts.length ? (
        <Grid item xs={12}>
          <Button color="primary" variant="contained" onClick={handleAlerts}>
            {t("alerts.clearSelectedAlerts")}{" "}
            {"(" + selectedAlerts.length + ")"}
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {recentAlerts ? (
          <SortableTable
            headCells={[
              {
                id: "alertType",
                numeric: false,
                disablePadding: false,
                label: t("alerts.alertType"),
              },
              {
                id: "UserId",
                numeric: false,
                disablePadding: false,
                label: t("alerts.active"),
              },
              {
                id: "message",
                numeric: false,
                disablePadding: false,
                label: t("alerts.message"),
              },
              {
                id: "updatedAt",
                numeric: false,
                disablePadding: false,
                label: t("alerts.updatedAt"),
              },
            ]}
            defaultSortRow={"updatedAt"}
            handleRowClick={handleRowClick}
            tableData={recentAlerts}
            rowsPerPage={200}
            checkBox={(e, v, d) => {
              var arr = [];
              if (v) {
                if (selectedAlerts) {
                  arr = [...selectedAlerts, d];
                } else {
                  arr.push(d);
                }
              } else {
                arr = selectedAlerts.filter((s) => s.id !== d.id);
              }
              setSelectedAlerts(arr);
            }}
            specialDataFormatting={[
              {
                id: "alertType",
                format: (data, r, con) => {
                  return resolveAlertType(con);
                },
              },
              {
                id: "UserId",
                format: (data) => {
                  return data ? t("no") : t("yes");
                },
              },
              {
                id: "updatedAt",
                format: (data) => {
                  return formatTimeWithTZOffset(data, ECABIN);
                },
              },
            ]}
          />
        ) : undefined}
      </Grid>
    </Grid>
  );
}
