import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Delete from "@material-ui/icons/Delete";
import Info from "@material-ui/icons/Info";
import Warning from "@material-ui/icons/Warning";
import DeviceSearchAutocomplete from "../../Components/DeviceSearchAutocomplete";
import Title from "../../Components/Title";
import { formatGoogleDriveMediaLink } from "../../Utils/Common";

const findColor = (data) => {
  if (!data) {
    return data;
  }
  if (data.toLowerCase().includes("grey")) {
    return "grey";
  }
  if (data.toLowerCase().includes("white")) {
    return "white";
  }
};

export default function OrderLine({
  item,
  selectedProducts,
  setSelectedProducts,
  totalItems,
  setTotalItems,
  totalPrice,
  setTotalPrice,
  onlyPrint,
  serialNumberAllocation,
  editedOrder,
  setEditedOrder,
  editable,
  width,
}) {
  const { t } = useTranslation();
  const [addingSerial, setAddingSerial] = useState();
  const [showDialog, setShowDialog] = useState();
  // const [hideSecondClass, setHideSecondClass] = useState(true);
  width = onlyPrint ? "" : width;

  const checkAvailability = (item) => {
    if (item.count) {
      var z = item.StockQuantities.find(
        (s) => s.Warehouse.name === "mailhouse"
      );
      if (!z || z?.free < item.count) {
        var total = 0;
        item.StockQuantities.forEach((s) => {
          total = total + s.free;
          return;
        });
        if (total >= item.count) {
          return t("orders.refillAvailable");
        }
        return t("orders.notEnoughItems");
      }
      return t("orders.availableForShipping");
    }
    return "";
  };

  const calculatePrice = (value, label, item) => {
    var num = Number(value);
    var unitprice = 0;
    var unitcount = 0;
    var discount = 1;
    var it;
    if (!isNaN(item.price)) {
      unitprice = item.price;
    }
    if (!isNaN(item.count)) {
      unitcount = item.count;
    }
    if (!isNaN(item.discount)) {
      discount = 1 - item.discount / 100;
    }
    var oldprice = unitcount * unitprice * discount;
    if (label === "count") {
      setTotalPrice(totalPrice - oldprice + num * unitprice * discount);
      setTotalItems(totalItems - unitcount + num);
      it = { ...item, count: num };
      setSelectedProducts(
        selectedProducts.map((m) => (m.id === it.id ? it : m))
      );
    } else if (label === "price") {
      setTotalPrice(totalPrice - oldprice + num * unitcount * discount);
      it = { ...item, price: num };
      setSelectedProducts(
        selectedProducts.map((m) => (m.id === it.id ? it : m))
      );
    } else if (label === "discount") {
      var x = 1 - num / 100;
      setTotalPrice(totalPrice - oldprice + unitprice * unitcount * x);
      it = { ...item, discount: num };
      setSelectedProducts(
        selectedProducts.map((m) => (m.id === it.id ? it : m))
      );
    }
  };

  useEffect(() => {
    if (!editable) {
      setAddingSerial();
    }
  }, [editable]);

  return (
    <Grid
      container
      style={{
        borderRadius: 5,
        backgroundColor: "#eeeeee",
        marginTop: 2,
        padding: 5,
      }}
      alignItems="center"
    >
      <Dialog
        open={!!showDialog}
        onClose={() => {
          setShowDialog();
        }}
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Title>{t("stockModule.warehouse")}</Title>
            </Grid>
            <Grid item xs={6}>
              <Title>{t("stockModule.free")}</Title>
            </Grid>

            {showDialog?.map((m, i) => (
              <React.Fragment key={i}>
                <Grid item xs={6}>
                  <Typography>{m.Warehouse.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{m.free}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
      {onlyPrint || width === "xs" ? null : (
        <>
          <Grid item xs={1} style={{ padding: 5 }}>
            <img
              alt="Item"
              src={formatGoogleDriveMediaLink(item.imageUrl)}
              width={50}
            />
          </Grid>
          <Grid
            item
            xs={editable && serialNumberAllocation ? 2 : 3}
            style={{ padding: 5 }}
          >
            <Typography>{item.sku}</Typography>{" "}
          </Grid>
        </>
      )}
      <Grid
        item
        sm={
          editable && serialNumberAllocation
            ? 2
            : onlyPrint
            ? 3 - (serialNumberAllocation ? 1 : 0)
            : 3
        }
        xs={12}
        style={width === "xs" ? { marginBottom: 5 } : { padding: 5 }}
      >
        <Typography>
          {item.name}
          {!onlyPrint ? (
            <IconButton
              onClick={() => {
                setShowDialog(item.StockQuantities);
              }}
            >
              <Info />
            </IconButton>
          ) : null}
        </Typography>
        {!onlyPrint ? (
          <Typography style={{ fontSize: 13 }}>
            {checkAvailability(item)}
          </Typography>
        ) : null}
      </Grid>
      {serialNumberAllocation &&
      (item.sku.includes("_EC_G_") || item.sku.includes("_EC_W_")) ? (
        <Grid item xs={editable ? 2 : 1}>
          {[
            ...editedOrder.Devices.filter(
              (f) => f.color === (item.sku.includes("_G_") ? "Grey" : "White")
            ),
            ...Array(
              item.count -
                serialNumberAllocation.filter(
                  (f) =>
                    f.color === (item.sku.includes("_G_") ? "Grey" : "White")
                ).length
            ),
          ].map((o, i) => {
            if (o) {
              return (
                <Typography key={i}>
                  {o.serialNumber}
                  {editedOrder.paymentStatus === "delivered" ? null : (
                    <IconButton
                      size="small"
                      disabled={!editable}
                      onClick={() => {
                        var arr = editedOrder.Devices.filter(
                          (dev) => dev.id !== o.id
                        );
                        var obj = editedOrder;
                        if (!arr || !arr.length) {
                          obj.Devices = [];
                        } else {
                          obj.Devices = arr;
                        }

                        setEditedOrder(JSON.parse(JSON.stringify(obj)));
                      }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Typography>
              );
            }
            return addingSerial === i ? (
              <>
                <DeviceSearchAutocomplete
                  key={i}
                  freeSearchFunction={false}
                  allowNewDevices={false}
                  allowGenericDevices={false}
                  fetchDevicesOnFocus={"ec"}
                  searchRestrictions={{
                    lcState: "ready",
                    color: findColor(item.name),
                    OrderId: null,
                    lcLocation: "mailhouse",
                    // hideSecondClass: hideSecondClass,
                  }}
                  additionalDataFields={[
                    "color",
                    "LifeCycleStates.quality",
                    "model",
                  ]}
                  onChange={(e, v) => {
                    if (!v || editedOrder.Devices.find((o) => o.id === v.id)) {
                      return;
                    }
                    var arr = [...editedOrder.Devices];
                    arr.push(v);
                    setEditedOrder({
                      ...editedOrder,
                      Devices: arr,
                    });
                  }}
                />
              </>
            ) : (
              <Tooltip
                title={
                  <Typography style={{ fontSize: 15 }}>
                    {t("orders.serialNumberRequired")}
                  </Typography>
                }
                key={i}
              >
                <Button
                  startIcon={<Warning style={{ color: "yellow" }} />}
                  size="small"
                  variant="outlined"
                  disabled={!editable}
                  onClick={() => {
                    setAddingSerial(i);
                  }}
                >
                  {t("orders.assignSerialNumber")}
                </Button>
              </Tooltip>
            );
          })}
        </Grid>
      ) : serialNumberAllocation && editable ? (
        <Grid item xs={2}></Grid>
      ) : serialNumberAllocation ? (
        <Grid item xs={1}></Grid>
      ) : null}
      <Grid
        item
        sm={onlyPrint ? 2 : 1}
        xs={4}
        style={width === "xs" ? {} : { padding: 5 }}
      >
        {onlyPrint ? (
          <Typography style={{ textAlign: "right" }}>{item.count}</Typography>
        ) : (
          <TextField
            label={t("count")}
            type="number"
            key={item.id}
            defaultValue={Number(item.count)}
            inputProps={{ min: 0 }}
            variant="outlined"
            onChange={(e) => {
              calculatePrice(e.target.value, "count", item);
            }}
          />
        )}
      </Grid>
      <Grid
        item
        sm={onlyPrint ? 2 : 1}
        xs={4}
        style={width === "xs" ? {} : { padding: 5 }}
      >
        {onlyPrint ? (
          <Typography style={{ textAlign: "right" }}>{item.price}</Typography>
        ) : (
          <TextField
            label={t("price")}
            type="number"
            key={item.id}
            inputProps={{ min: 0 }}
            variant="outlined"
            defaultValue={item.price ? Number(item.price) : ""}
            onChange={(e, v) => {
              calculatePrice(e.target.value, "price", item);
            }}
          />
        )}
      </Grid>
      <Grid
        item
        sm={onlyPrint ? 2 : 1}
        xs={4}
        style={width === "xs" ? {} : { padding: 5 }}
      >
        {onlyPrint ? (
          <Typography style={{ textAlign: "right" }}>
            {item.discount || 0}%
          </Typography>
        ) : (
          <TextField
            label={t("orders.discount") + "%"}
            fullWidth
            type="number"
            key={item.id}
            inputProps={{ min: 0, max: 100 }}
            variant="outlined"
            defaultValue={item.discount ? Number(item.discount) : ""}
            onChange={(e, v) => {
              calculatePrice(e.target.value, "discount", item);
            }}
          />
        )}
      </Grid>
      <Grid
        item
        sm={1 + (onlyPrint ? 1 : 0)}
        xs={2}
        style={{ padding: 5, textAlign: "right" }}
      >
        <Typography>
          {(isNaN(item.count * item.price)
            ? 0
            : item.count *
              item.price *
              (item.discount ? 1 - item.discount / 100 : 1)
          ).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item sm={1} xs={3} style={{ padding: 5 }}>
        {!onlyPrint || editable ? (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => {
              calculatePrice(0, "count", item);
              setSelectedProducts([
                ...selectedProducts.filter((d) => d.id !== item.id),
              ]);
            }}
          >
            {t("remove")}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}
