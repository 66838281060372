import { useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import Title from "../../Components/Title";
import {
  ECABIN,
  XCRYO,
  checkAdmin,
  getDeviceType,
  getLastParameter,
  getLogoImage,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
const server = EnvSettings.server;

function tooltipContent(tooltipProps) {
  if (tooltipProps.payload === null) {
    return undefined;
  }
  let name = tooltipProps.payload[0]?.payload.name;
  let value = tooltipProps.payload[0]?.payload.value.toFixed(1);
  let qty = tooltipProps.payload[0]?.payload.qty;
  let avg = (
    tooltipProps.payload[0]?.payload.value /
    tooltipProps.payload[0]?.payload.qty
  ).toFixed(1);
  let bgc = "white";
  return (
    <div
      style={{
        borderRadius: 5,
        borderColor: "black",
        borderWidth: 1,
        padding: 10,
        backgroundColor: bgc,
      }}
    >
      {name !== null ? (
        <React.Fragment>
          {" "}
          {name}
          <br />
        </React.Fragment>
      ) : undefined}
      {qty !== null ? (
        <React.Fragment>
          Runtimes: {qty}
          <br />
        </React.Fragment>
      ) : undefined}
      {value !== null ? (
        <React.Fragment>
          Total: {value} kg
          <br />
        </React.Fragment>
      ) : undefined}
      {avg !== null ? (
        <React.Fragment>
          AVG: {avg} kg
          <br />
        </React.Fragment>
      ) : undefined}
    </div>
  );
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return percent !== 0 ? (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  ) : null;
};

function ConsumptionPieChart(props) {
  const theme = useTheme();
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.primary.dark,
  ];
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="center"
      //style={{ height: "60%" }}
    >
      <Grid item>
        <Typography>{props.title}</Typography>
      </Grid>
      <Grid item>
        <PieChart width={150} height={150}>
          <Pie
            data={props.data}
            cx={75}
            cy={75}
            labelLine={false}
            isAnimationActive={false}
            label={(props) => renderCustomizedLabel(props)}
            //outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={(props) => tooltipContent(props)} />
        </PieChart>
      </Grid>
    </Grid>
  );
}

function formatPieChartData(data) {
  if (!data.precool.count && !data.session.count) return;
  return [
    {
      name: "Precool",
      value: data.precool.rows[0]?.cons || 0,
      qty: data.precool.count,
    },
    {
      name: "Session",
      value: data.session.rows[0]?.cons || 0,
      qty: data.session.count,
    },
  ];
}

function PieCharts() {
  const device_id = getLastParameter();
  const [dailyConsumption, setDailyConsumption] = useState();
  const [monthlyConsumption, setMonthlyConsumption] = useState();
  const [consumption, setConsumption] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const getConsumption = (timeperiod) => {
      var offset = 0;
      if (timeperiod && timeperiod !== 1) {
        offset = timeperiod * 24 * 60 * 60 * 1000;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          time_period: timeperiod
            ? new Date(new Date().getTime() - offset)
                .toISOString()
                .substring(0, 10) + " 00:00:00"
            : undefined,
          deviceid: device_id,
        }),
      };
      fetch(server + "/get_consumption", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (timeperiod === 0 || timeperiod === 1) {
              setDailyConsumption(formatPieChartData(result));
            } else if (timeperiod === 30) {
              setMonthlyConsumption(formatPieChartData(result));
            } else {
              setConsumption(formatPieChartData(result));
            }
          }
        });
    };
    getConsumption(1);
    getConsumption(30);
    getConsumption();
  }, [dispatch, device_id]);

  return (
    <Grid item sm={8} xs={12}>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
        style={{ textAlign: "right", marginRight: "20%" }}
      >
        {t("deviceDetails.consumptionHeader")}
      </Typography>
      <Grid
        container
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          height: "80%",
        }}
      >
        <Grid item sm={4} xs={12} style={{ height: "100%" }}>
          {dailyConsumption ? (
            <ConsumptionPieChart
              title={t("deviceDetails.dailyConsumption")}
              data={dailyConsumption}
            />
          ) : undefined}
        </Grid>
        <Grid item sm={4} xs={12} style={{ height: "100%" }}>
          {monthlyConsumption ? (
            <ConsumptionPieChart
              title={t("deviceDetails.monthlyConsumption")}
              data={monthlyConsumption}
            />
          ) : undefined}
        </Grid>
        <Grid item sm={4} xs={12} style={{ height: "100%" }}>
          {consumption ? (
            <ConsumptionPieChart
              title={t("deviceDetails.totalConsumption")}
              data={consumption}
            />
          ) : undefined}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function DeviceInformation({ deviceData }) {
  const classes = useStyles();

  return deviceData ? (
    <Paper className={classes.paper}>
      <Grid container style={{ flexDirection: "row" }}>
        {/* Image */}
        <Grid item sm={2} xs={12}>
          <img
            alt={"Device"}
            src={getLogoImage(deviceData.serialNumber)}
            style={{ maxWidth: 300, maxHeight: 300 }}
          />
        </Grid>
        {/* Device definition */}
        <Grid item sm={2} xs={12}>
          <Title>{getDeviceType(deviceData.serialNumber)}</Title>
          <Typography>SerialNo: {deviceData.serialNumber}</Typography>
          <Typography>Model: {deviceData.model}</Typography>
          <Typography>{deviceData.deviceName}</Typography>
          <Typography>Version: {deviceData.version}</Typography>
          <Typography>Location: {deviceData.location}</Typography>
          <Typography>
            MacAddress: {checkAdmin() ? deviceData.macAddress : ""}
          </Typography>
          <Typography>
            TVID: {checkAdmin() ? deviceData.teamviewerId : ""}
          </Typography>
        </Grid>
        {/* Graphs of consumption */}
        {!(
          getDeviceType(deviceData.serialNumber) === XCRYO ||
          getDeviceType(deviceData.serialNumber) === ECABIN
        ) ? (
          <PieCharts />
        ) : null}
      </Grid>
    </Paper>
  ) : null;
}
