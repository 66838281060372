import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch } from "react-redux";
import Title from "../../Components/Title";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
const server = EnvSettings.server;

const SessionPresetLimits = {
  FanSpeedTarget: [0, 100],
  FanStartTemperature: [5, 16],
  FanStopTemperature: [4, 15],
  FanSpeedIncrement: [1, 100],
  FanSpeedIncrementInterval: [1, 180],
  AfterCoolingDuration: [0, 240],
  AfterCoolingSpeed: [0, 100],
};

const checkForLimits = (value, kname) => {
  if (
    value >= SessionPresetLimits[kname][0] &&
    value <= SessionPresetLimits[kname][1]
  ) {
    return true;
  }
  return false;
};

const checkPresetEntries = (obj) => {
  if (
    Object.entries(obj).length === Object.entries(SessionPresetLimits).length
  ) {
    return Object.entries(obj);
  }
  var obj2 = JSON.parse(JSON.stringify(obj));
  Object.keys(SessionPresetLimits).forEach((k, i) => {
    if (!obj2[k]) {
      obj2 = { ...obj2, [k]: "" };
    }
  });
  return Object.entries(obj2);
};

export default function EcabinSessionPresets(props) {
  const { t } = useTranslation();
  const [editedSessionPresets, setEditedSessionPresets] = useState();
  const [sessionPresets, setSessionPresets] = useState();
  const [failedPresets, setFailedPresets] = useState({});
  const dispatch = useDispatch();

  const findFaultyPreset = (preset, key) => {
    if (!failedPresets || !Object.keys(failedPresets).length) return false;
    var found = false;
    Object.keys(failedPresets).forEach((element, i) => {
      if (element === preset) {
        Object.keys(failedPresets[preset]).forEach((k, i) => {
          if (k === key) {
            found = true;
          }
        });
      }
    });
    return found;
  };

  const checkForChanges = () => {
    if (editedSessionPresets && Object.keys(editedSessionPresets).length) {
      if (sessionPresets && Object.keys(sessionPresets).length) {
        if (
          Object.keys(sessionPresets).length ===
          Object.keys(editedSessionPresets).length
        ) {
          var bol = true;
          Object.keys(sessionPresets).forEach((p, i) => {
            if (!shallowEqual(sessionPresets[p], editedSessionPresets[p])) {
              bol = false;
            }
          });
          return bol;
        }
        return false;
      }
      return false;
    }
    return true;
  };

  const updateSessionPresets = () => {
    if (
      !editedSessionPresets ||
      Object.keys(editedSessionPresets).length === 0
    ) {
      dispatch(
        showPopupSnackbar({
          error: true,
          message: "SessionPresets can't be empty",
        })
      );
      return;
    }
    var errors = 0;
    var obj = {};
    Object.keys(editedSessionPresets).forEach((element, i) => {
      Object.keys(editedSessionPresets[element]).forEach((k, ii) => {
        if (!checkForLimits(editedSessionPresets[element][k], k)) {
          if (obj[element]) {
            obj = { ...obj, [element]: { ...obj[element], [k]: 1 } };
          } else {
            Object.assign(obj, { [element]: { [k]: 1 } });
          }
          errors++;
        }
      });
    });
    setFailedPresets(obj);
    if (errors !== 0) return;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: getLastParameter(),
        session_presets: editedSessionPresets,
      }),
    };
    fetch(server + "/edit_session_presets", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }
      });
  };

  useEffect(() => {
    const getSessionPresets = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          deviceid: getLastParameter(),
        }),
      };
      fetch(server + "/get_session_presets", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (result.error) {
            return;
          } else if (result.session_presets) {
            var obj = JSON.parse(JSON.stringify(result.session_presets));
            var obj2 = JSON.parse(JSON.stringify(result.session_presets));
            setSessionPresets(obj);
            setEditedSessionPresets(obj2);
          } else {
            setSessionPresets();
            setEditedSessionPresets();
          }
        });
    };
    getSessionPresets();
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={3} style={{ flexDirection: "row" }}>
        <Grid item>
          <Title>{t("deviceDetails.sessionPresets")}</Title>
        </Grid>
      </Grid>
      {failedPresets &&
        editedSessionPresets &&
        Object.keys(editedSessionPresets).map((preset, key) => (
          <Grid
            key={key}
            container
            spacing={3}
            style={{
              marginTop: 10,
              borderBottom: "solid 1px lightgray",
              padding: 15,
            }}
          >
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                required
                // disabled
                fullWidth
                label={t("name")}
                defaultValue={preset}
                onChange={(e) => {
                  var obj = JSON.stringify(editedSessionPresets);
                  obj = JSON.parse(obj);
                  var obj2 = { [e.target.value]: {} };
                  Object.keys(obj[preset]).forEach((k, i) => {
                    Object.assign(obj2[e.target.value], {
                      [k]: obj[preset][k],
                    });
                  });
                  Object.assign(obj, obj2);
                  delete obj[preset];
                  setEditedSessionPresets(obj);
                }}
              />
            </Grid>
            {checkPresetEntries(editedSessionPresets[preset]).map(
              ([k, value], i) => (
                <Grid key={i} item xs={12} sm={3}>
                  <TextField
                    name={k}
                    type={k !== "Name" ? "number" : ""}
                    variant="outlined"
                    error={
                      failedPresets &&
                      Object.keys(failedPresets).length &&
                      findFaultyPreset(preset, k)
                        ? true
                        : false
                    }
                    required
                    fullWidth
                    // disabled
                    id={k}
                    label={k}
                    defaultValue={value}
                    onChange={(e) => {
                      var ed = { ...editedSessionPresets };
                      var obj = ed[preset];
                      obj = { ...obj, [k]: parseInt(e.target.value) };
                      Object.assign(ed[preset], obj);
                      setEditedSessionPresets(ed);
                    }}
                  />
                </Grid>
              )
            )}
            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  var obj = JSON.parse(JSON.stringify(editedSessionPresets));
                  delete obj[preset];
                  setEditedSessionPresets({});
                  setTimeout(() => {
                    setEditedSessionPresets(obj);
                  }, 1);
                }}
              >
                {t("remove")}
              </Button>
            </Grid>
          </Grid>
        ))}
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <Grid item>
          <Button
            // disabled
            variant="outlined"
            onClick={() => {
              var obj = JSON.parse(JSON.stringify(editedSessionPresets));
              var z = 0;
              while (obj["NewPreset" + z]) {
                z++;
              }
              obj = {
                ...obj,
                ["NewPreset" + z]: {
                  FanSpeedTarget: 100,
                  FanStartTemperature: 8,
                  FanStopTemperature: 7,
                  FanSpeedIncrement: 2,
                  FanSpeedIncrementInterval: 1,
                  AfterCoolingDuration: 0,
                  AfterCoolingSpeed: 30,
                },
              };
              setEditedSessionPresets(obj);
            }}
          >
            <Typography>{t("add")}</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            // disabled
            variant="outlined"
            onClick={() => {
              if (sessionPresets) {
                var obj = JSON.parse(JSON.stringify(sessionPresets));
                setEditedSessionPresets({});
                // This timeout makes sure that the object is truly updated due to weirdness in React
                setTimeout(() => {
                  setEditedSessionPresets(obj);
                }, 1);
              } else {
                setEditedSessionPresets(undefined);
              }
              setFailedPresets({});
            }}
          >
            <Typography>{t("reset")}</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            disabled={checkForChanges()}
            onClick={() => {
              updateSessionPresets();
            }}
          >
            <Typography>{t("save")}</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
