import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import SortableTable from "../../Components/SortableTable";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getDeviceType,
  getSessionType,
} from "../../Utils/Common";
import { useStyles } from "../../Utils/Styles";
import ErrorCodeItem from "./ErrorCodeItem";

export default function DetailContainer({ chartData }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  function downloadJSONAsCSV(jsonData) {
    // Fetch JSON data from the endpoint
    // Convert JSON data to CSV
    let csvData = jsonToCsv(jsonData, ["Measurements"]); // Add .items.data
    // Create a CSV file and allow the user to download it
    let blob = new Blob([csvData], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download =
      getSessionType(
        getDeviceType(jsonData.Device.serialNumber),
        jsonData.type
      ) +
      "-" +
      jsonData.startTime +
      ".csv";
    document.body.appendChild(a);
    a.click();
  }

  const checkData = (data) => {
    if (!data) {
      return data;
    }
    if (Array.isArray(data)) {
      return data;
    }
    if (Number.isFinite(data)) {
      return data;
    }
    if (Object.keys(data)) {
      return JSON.stringify(data);
    }
    return data;
  };

  const getHeadCells = (data) => {
    var arr = [];
    var exclude = [
      "Measurements",
      "Device",
      "Notes",
      "createdAt",
      "updatedAt",
      "id",
      "type",
      "DeviceId",
      // "errorCode",
    ];
    Object.keys(data).forEach((c) =>
      chartData[c] && !exclude.find((e) => e === c)
        ? arr.push({
            id: c,
            numeric: false,
            disablePadding: false,
            label: t("sessionDetails." + c),
          })
        : null
    );
    return arr;
  };

  function jsonToCsv(jsonData, arrayFields = []) {
    let csv = "";
    // Get the headers
    let infoFields = Object.keys(jsonData).filter(
      (k) => !arrayFields.find((a) => a === k)
    );
    // Replace Dev Fields with serno
    infoFields = infoFields.filter((a) => a !== "Device" && a !== "DeviceId");
    infoFields.push("serialNumber");
    infoFields.forEach((inf, idx) => {
      if ((inf === "serialNumber" || jsonData[inf]) && inf !== "errorCode") {
        csv += inf + (idx === infoFields.length - 1 ? "" : ";");
      }
    });
    csv += "\n";
    infoFields.forEach((i, idx) => {
      if (i === "startTime") {
        csv +=
          formatTimeWithTZOffset(jsonData[i], jsonData.Device.serialNumber) +
          (idx === infoFields.length - 1 ? "" : ";");
      } else if (i === "type") {
        csv +=
          getSessionType(
            getDeviceType(jsonData.Device.serialNumber),
            jsonData[i]
          ) + (idx === infoFields.length - 1 ? "" : ";");
      } else if (i === "serialNumber") {
        csv +=
          jsonData.Device.serialNumber +
          (idx === infoFields.length - 1 ? "" : ";");
      } else if (i === "Notes") {
        csv +=
          jsonData[i].map(
            (n, idd) => n.text + (idd !== jsonData[i].length - 1 ? "/" : "")
          ) + (idx === infoFields.length - 1 ? "" : ";");
      } else if (i === "errorCode") {
      } else if (jsonData[i]) {
        csv +=
          checkData(jsonData[i]) + (idx === infoFields.length - 1 ? "" : ";");
      }
    });
    csv += "\n\n\n";
    // Add the data
    arrayFields.forEach((a) => {
      let headers = Object.keys(jsonData[a][0]);
      headers.forEach((h, idx) => {
        if (jsonData[a][0][h] === null) {
          // Do nothing
        } else if (h === "id") {
          csv += "time" + (idx === headers.length - 1 ? "" : ";");
        } else if (h === "SessionId") {
          // Do nothing
        } else {
          csv += h + (idx === headers.length - 1 ? "" : ";");
        }
      });
      csv += "\n";
      // csv += headers.join(";") + "\n";
      jsonData[a].forEach((j, idx) => {
        headers.forEach((header, ii) => {
          if (header === "id") {
            csv += idx + (ii === headers.length - 1 ? "" : ";");
          } else if (header === "SessionId") {
          } else if (j[header] || j[header] === 0) {
            csv += JSON.stringify(j[header]) + ";";
          }
        });
        // Add JSON.stringify statement
        csv += "\n";
      });
    });
    return csv;
  }

  return (
    <Grid item xs={12}>
      {chartData ? (
        <Paper className={fixedHeightPaper}>
          <SortableTable
            headCells={getHeadCells(chartData)}
            defaultSortRow={"startTime"}
            tableData={[chartData]}
            rowsPerPage={1}
            disablePagination={true}
            handleRowClick={() => {}}
            specialDataFormatting={[
              {
                id: "startTime",
                format: (data) => {
                  return formatTimeWithTZOffset(data, ECABIN);
                },
              },
              {
                id: "errorCode",
                format: (data) => {
                  return <ErrorCodeItem data={data} />;
                },
              },
            ]}
          />

          <Grid item style={{ marginTop: 5 }}>
            <Button
              onClick={() => {
                downloadJSONAsCSV(chartData);
              }}
              variant="outlined"
              endIcon={<GetAppIcon />}
            >
              {t("download") + " .csv"}
            </Button>
          </Grid>
        </Paper>
      ) : undefined}
    </Grid>
  );
}
