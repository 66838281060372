import {
  CircularProgress,
  Link,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Cell, Legend, Pie, PieChart } from "recharts";
import ContactInfoBadge from "../../Components/Contacts/ContactInfoBadge";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getToken,
  getUser,
  readClientDataFromLCS,
  requestErrorHandler,
  resolveTransferLocation,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

export default function Dashboard(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [transferList, setTransferList] = useState();
  const [demoList, setDemoList] = useState();
  const [thisMonthActivity, setThisMonthActivity] = useState();
  const [thisYearActivity, setThisYearActivity] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     *
     * @param {array} data List of devices
     * @returns
     */
    const formatTransfer = (data) => {
      var arr = [];
      data.forEach((el) => {
        // Find the element with the dispatch
        var dispatch_element = el.LifeCycleStates.find(
          (o) => o.state === "dispatched" && o.UserId === getUser().userId
        );
        var element = el.LifeCycleStates.reduce((a, b) =>
          a.id > b.id ? a : b
        );
        var obj = {
          createdAt: formatTimeWithTZOffset(dispatch_element.createdAt, ECABIN),
          clientInfoString: formatClientInfoString(dispatch_element.clientInfo),
          serialNumber: el.serialNumber,
          withClient:
            dispatch_element.id === element.id ||
            (JSON.stringify(element.clientInfo) ===
              JSON.stringify(dispatch_element.clientInfo) &&
              element.location === dispatch_element.location)
              ? t("yes")
              : `${t("returned")}`,
          Company: dispatch_element.Company,
          Contact: dispatch_element.Contact,
        };
        arr.push({
          ...element,
          ...obj,
        });
      });
      return arr;
    };
    const getTransfers = () => {
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
        }),
      };
      fetch(server + "/get_personal_dispatches", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            var a = formatTransfer(result.lcs);
            setTransferList(a);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    getTransfers();
  }, [dispatch, t]);

  useEffect(() => {
    const formatTransfer = (data) => {
      var arr = [];
      data.forEach((el) => {
        // Tässä tämä toimii, koska LCSiä haetaan tasan yksi (viimeisin)
        var element = el.LifeCycleStates[0];
        var obj = {
          createdAt: "",
          location: "",
          serialNumber: "",
          clientInfoString: "",
          notes: "",
        };
        arr.push({
          ...obj,
          ...{
            ...element,
            createdAt: formatTimeWithTZOffset(element.createdAt, ECABIN),
            location: t(
              "transferLocations." + resolveTransferLocation(element)
            ),
            serialNumber: el.serialNumber,
            clientInfoString: formatClientInfoString(element),
            notes: element.Notes.length
              ? element.Notes.map((n) => n.text).join(" / ")
              : "",
          },
        });
      });
      return arr;
    };
    const getTransfers = () => {
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
        }),
      };
      fetch(server + "/get_personal_demos", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            var a = formatTransfer(result.lcs);
            setDemoList(a);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    getTransfers();
  }, [dispatch, t]);
  // Latest demos
  // Latest dispatches

  useEffect(() => {
    if (transferList && demoList) {
      var month =
        new Date(new Date().getTime()).toISOString().substring(0, 8) +
        "01 00:00:00";
      var x = transferList.filter((item) => item.createdAt > month);
      var y = demoList.filter((item) => item.createdAt > month);
      var arr = [
        {
          name: t("mainSideBar.dispatches"),
          value: x.length,
        },
        { name: t("mainSideBar.demos"), value: y.length },
      ];
      setThisMonthActivity(arr);
      var year =
        new Date(new Date().getTime()).toISOString().substring(0, 5) +
        "01-01 00:00:00";
      x = transferList.filter(
        (item) => item.createdAt > year && item.withClient !== t("returned")
      );
      y = demoList.filter((item) => item.createdAt > year);
      arr = [
        {
          name: t("mainSideBar.dispatches"),
          value: x.length,
        },
        { name: t("mainSideBar.demos"), value: y.length },
      ];
      setThisYearActivity(arr);
    }
  }, [transferList, demoList, t]);
  const COLORS = ["#0088FE", "#2f8088", "#FFBB28", "#FF8042"];
  return (
    <Grid container spacing={3}>
      <Grid item md={4} xs={12}>
        <Paper className={classes.paper}>
          {loading ? <CircularProgress /> : null}
          {thisMonthActivity ? (
            <Grid container justify="center">
              <Grid item xs={12}>
                <Title style={{ textAlign: "center" }}>
                  {new Date()
                    .toLocaleString("fi-FI", { month: "long" })
                    .toUpperCase()}
                </Title>
              </Grid>
              <Grid item xs={8}>
                <PieChart width={200} height={200}>
                  <Legend
                    payload={thisMonthActivity.map((item, index) => ({
                      id: item.name,
                      type: "square",
                      value: `${item.name} (${item.value})`,
                      color: COLORS[index % COLORS.length],
                    }))}
                  />
                  <Pie
                    data={thisMonthActivity}
                    cx={95}
                    cy={100}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {thisMonthActivity.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </Grid>
            </Grid>
          ) : null}
          {thisYearActivity ? (
            <Grid container justify="center" style={{ marginTop: 10 }}>
              <Grid item xs={12}>
                <Title style={{ textAlign: "center" }}>
                  {new Date().toISOString().substring(0, 4)}
                </Title>
              </Grid>
              <Grid item xs={8}>
                <PieChart width={200} height={200}>
                  <Legend
                    payload={thisYearActivity.map((item, index) => ({
                      id: item.name,
                      type: "square",
                      value: `${item.name} (${item.value})`,
                      color: COLORS[index % COLORS.length],
                    }))}
                  />
                  <Pie
                    data={thisYearActivity}
                    cx={95}
                    cy={100}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {thisYearActivity.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </Grid>
            </Grid>
          ) : null}
        </Paper>
      </Grid>
      <Grid item md={4} xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("latestDispatches")}</Title>
          {transferList
            ? transferList
                .sort((a, b) =>
                  a.createdAt > b.createdAt
                    ? -1
                    : b.createdAt > a.createdAt
                    ? 1
                    : 0
                )
                .map((item, i) =>
                  i < 5 ? (
                    <ListItem button key={i}>
                      <ListItemText
                        disableTypography
                        primary={
                          <>
                            <ContactInfoBadge
                              showBrief={true}
                              clientInfoObject={readClientDataFromLCS(item)}
                              noPaper={true}
                            />
                            {item.serialNumber}
                          </>
                        }
                        style={{ fontSize: 15 }}
                      />
                    </ListItem>
                  ) : null
                )
            : null}
          <Link href={"/dispatches"}>{t("showMore")}</Link>
        </Paper>
      </Grid>
      <Grid item md={4} xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("latestDemos")}</Title>
          {demoList
            ? demoList
                .sort((a, b) =>
                  a.createdAt > b.createdAt
                    ? -1
                    : b.createdAt > a.createdAt
                    ? 1
                    : 0
                )
                .map((item, i) =>
                  i < 5 ? (
                    <ListItem button key={i}>
                      <ListItemText
                        disableTypography
                        primary={`${
                          formatClientInfoString(readClientDataFromLCS(item)) ||
                          t("transferLocations.car")
                        } ${item.serialNumber}`}
                        style={{ fontSize: 15 }}
                      />
                    </ListItem>
                  ) : null
                )
            : null}
          <Link href={"/demos"}>{t("showMore")}</Link>
        </Paper>
      </Grid>
    </Grid>
  );
}
