import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Title from "../../Components/Title";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
const server = EnvSettings.server;
export default function CryoCabinSessionPresets(props) {
  const { t } = useTranslation();
  const [editedSessionPresets, setEditedSessionPresets] = useState();
  const [sessionPresets, setSessionPresets] = useState();
  const [failedPresets, setFailedPresets] = useState([]);
  const dispatch = useDispatch();

  const findFaultyPreset = (index, name) => {
    var found = false;
    failedPresets.forEach((element) => {
      if (element[index]) {
        element[index].forEach((el) => {
          if (el === name) {
            found = true;
          }
        });
      }
    });
    return found;
  };

  const checkForChanges = () => {
    if (editedSessionPresets && editedSessionPresets.length) {
      if (sessionPresets && sessionPresets.length) {
        if (sessionPresets.length === editedSessionPresets.length) {
          return !sessionPresets.every(
            (value, index) => value === editedSessionPresets[index]
          );
        }
      }
      return false;
    }
    return true;
  };

  const updateSessionPresets = () => {
    var arr = [];
    editedSessionPresets.forEach((element, i) => {
      var obj = { [i]: [] };
      if (element.Temperature > -80 || element.Temperature < -170) {
        obj[i].push("Temperature");
      }
      if (element.Duration > 180 || element.Duration < 90) {
        obj[i].push("Duration");
      }
      if (obj[i].length) {
        arr.push(obj);
      }
    });
    setFailedPresets([...arr]);
    if (arr.length) return;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: getLastParameter(),
        session_presets: editedSessionPresets,
      }),
    };
    fetch(server + "/edit_session_presets", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }
      });
  };

  const checkArray = (data) => {
    var arr = [...data];
    data.forEach((element, i) => {
      if (element.Temperature === "" && element.Duration === "") {
        arr.splice(i, 1);
      }
    });
    return arr;
  };

  useEffect(() => {
    const getSessionPresets = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          deviceid: getLastParameter(),
        }),
      };
      fetch(server + "/get_session_presets", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (result.error) {
            return;
          } else if (result.session_presets) {
            if (Array.isArray(result.session_presets.Presets)) {
              setSessionPresets(checkArray(result.session_presets.Presets));
              setEditedSessionPresets(
                checkArray(result.session_presets.Presets)
              );
            } else if (Array.isArray(result.session_presets.Presets.Preset)) {
              setSessionPresets(
                checkArray(result.session_presets.Presets.Preset)
              );
              setEditedSessionPresets(
                checkArray(result.session_presets.Presets.Preset)
              );
            } else if (result.session_presets.Presets?.Preset) {
              setSessionPresets([result.session_presets.Presets.Preset]);
              setEditedSessionPresets([result.session_presets.Presets.Preset]);
            }
          } else {
            setSessionPresets([]);
            setEditedSessionPresets([]);
          }
        });
    };
    getSessionPresets();
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={3} style={{ flexDirection: "row" }}>
        <Grid item>
          <Title>{t("deviceDetails.sessionPresets")}</Title>
        </Grid>
      </Grid>
      {failedPresets &&
        editedSessionPresets &&
        editedSessionPresets.map((preset, key) => (
          <Grid key={key} container spacing={3} style={{ marginTop: 10 }}>
            {Object.keys(preset).map((k, i) => (
              <Grid key={i} item xs={12} sm={3}>
                <TextField
                  name={k}
                  type={k !== "Name" ? "number" : ""}
                  variant="outlined"
                  error={
                    failedPresets &&
                    failedPresets.length &&
                    findFaultyPreset(key, k)
                      ? true
                      : false
                  }
                  required
                  fullWidth
                  id={k}
                  label={t(k.toLowerCase())}
                  value={Array.isArray(preset[k]) ? preset[k][0] : preset[k]}
                  onChange={(e) => {
                    var arr = [];
                    if (editedSessionPresets && editedSessionPresets.length) {
                      arr = [...editedSessionPresets];
                    }
                    var obj = arr[key];
                    obj = { ...obj, [k]: e.target.value };
                    arr[key] = obj;

                    setEditedSessionPresets(arr);
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12} sm={2}>
              <Button
                variant="outlined"
                onClick={() => {
                  var arr = [];
                  if (editedSessionPresets && editedSessionPresets.length) {
                    arr = [...editedSessionPresets];
                  }
                  arr.splice(key, 1);
                  setEditedSessionPresets([...arr]);
                }}
              >
                {t("remove")}
              </Button>
            </Grid>
          </Grid>
        ))}
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => {
              var arr = [];
              if (editedSessionPresets && editedSessionPresets.length) {
                arr = [...editedSessionPresets];
              }
              arr.push({ Duration: "", Temperature: "", Name: "" });
              setEditedSessionPresets([...arr]);
            }}
          >
            <Typography>{t("add")}</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => {
              if (sessionPresets && sessionPresets.length) {
                setEditedSessionPresets([...sessionPresets]);
              } else {
                setEditedSessionPresets([]);
              }
              setFailedPresets([]);
            }}
          >
            <Typography>{t("reset")}</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            disabled={
              checkForChanges()
              /*
                sessionPresets &&
                editedSessionPresets &&
                sessionPresets.length === editedSessionPresets.length &&
                sessionPresets.every(
                  (value, index) => value === editedSessionPresets[index]
                )
                  ? true
                  : false*/
            }
            onClick={() => {
              updateSessionPresets();
            }}
          >
            <Typography>{t("save")}</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
