import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../../../Utils/Common";

import EnvSettings from "../../../Utils/EnvSettings";

const server = EnvSettings.server;

export default function PackageDialog({ pack, setPack, _edit, _setEdit }) {
  const { t } = useTranslation();
  const [obj, setObj] = useState();
  const dispatch = useDispatch();
  const [shippingCrates, setShippingCrates] = useState([]);

  const handleExit = () => {
    setObj();
    setPack();
  };

  useEffect(() => {
    if (pack && Object.keys(pack).length) {
      setObj(pack);
    } else {
      setObj({
        type: "",
        count: 0,
        width: 0,
        height: 0,
        length: 0,
      });
    }
  }, [pack, setObj]);

  useEffect(() => {
    const getCrates = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
        }),
      };
      fetch(server + "/get_shipping_crates", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setShippingCrates(result.crates);
            // setTagList(result.stock);
          }
        });
    };
    if (!shippingCrates.length) {
      getCrates();
    }
  }, [shippingCrates, setShippingCrates, dispatch]);

  return (
    <Dialog open={!!pack} onExit={handleExit}>
      <DialogTitle>
        {obj && obj.index !== undefined ? t("edit") : t("add")} {t("package")}
      </DialogTitle>
      {obj ? (
        <DialogContent>
          {obj && obj.index === undefined ? (
            <>
              <Typography>{t("stockModule.chooseDefaultCrate")}</Typography>
              <TextField
                variant="outlined"
                select
                fullWidth
                label={t("stockModule.defaultCrates")}
                onChange={(e, f) => {
                  if (e && e.target && e.target.value) {
                    var v = { count: 1 };
                    Object.keys(obj).forEach((k) => {
                      if (e.target.value[k] !== undefined) {
                        v[k] = e.target.value[k];
                      }
                    });
                    setObj();
                    setTimeout(() => {
                      setObj(JSON.parse(JSON.stringify(v)));
                    }, 1);
                  }
                }}
              >
                {shippingCrates.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>{d.name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ color: "grey" }}>
                          {d.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            </>
          ) : null}
          {obj &&
            Object.keys(obj).map((o, i) =>
              o !== "index" ? (
                <TextField
                  key={i}
                  variant="outlined"
                  label={t(o)}
                  defaultValue={obj[o]}
                  fullWidth
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    var z = { ...obj, [o]: e.target.value };
                    setObj({ ...z });
                  }}
                />
              ) : null
            )}
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setObj();
            setPack();
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            var v = JSON.parse(JSON.stringify({ ..._edit }));
            if (!v.packages) {
              v.packages = [];
            }
            if (v.packages && obj.index !== undefined) {
              var x = obj.index;
              delete obj.index;
              v.packages[x] = { ...obj };
            } else if (v.packages) {
              v.packages = [...v.packages, { ...obj }];
            } else {
              v.packages = [{ ...obj }];
            }
            _setEdit({ ...v });
            handleExit();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
